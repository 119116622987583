import React, {useEffect, useCallback, useState} from "react";
import {createUseStyles} from 'react-jss';
import logo from './images/logo-white.png';
import headerStyles from "./header.styles";
import Menus from "./Menus";
import {useHeader} from "../../context/HeaderContext";
import { useBuildingsCat } from "../../context/BuildingCategoriesContext";
import Modal from "../Modal/Modal";
import Contact from "../Contact/Contact";

const useStyles = createUseStyles(headerStyles);

const Header = ({ contact, onBuildingClick, showHamburgerMenu }) => {
    const classes = useStyles();
    const { activeId, activeObjectName, setActiveObjectName } = useHeader();
    const { buildingsCat } = useBuildingsCat();
    const parentCat = buildingsCat.filter(({ parent }) => parent === 0);
    const [showContactModal, setShowContactModal] = useState(false);
    const [buildingDescUrl, setBuildingDescUrl] = useState('');

    useEffect(() => {
        const filterActiveObject = buildingsCat?.filter((building) => building.id === +activeId);
        setActiveObjectName(filterActiveObject[0]?.name);
        setBuildingDescUrl(filterActiveObject[0]?.acf?.building_description_url);
    }, [setActiveObjectName]);

    const onClickHandler = useCallback((e) => {
        e.preventDefault();
        setShowContactModal(true);
    }, []);

    const onModalClose = useCallback(() => {
        setShowContactModal(false);
    }, []);

    return(
        <>
            <div className={classes.header}>
                <div className={`${classes.fullWidth} ${classes.menus}`}>
                    <Menus
                        parentCat={parentCat}
                        onBuildingClick={onBuildingClick}
                        showHamburgerMenu={showHamburgerMenu}
                    />
                </div>
                <div className={`${classes.logo} ${classes.fullWidth}`}>
                    {!activeObjectName || !contact ? (
                        <a href="https://monopolcity.com" className={classes.objectNameInner}>
                            <img src={logo} alt="" width="160px"/>
                        </a>
                    ) : (
                        <a href={buildingDescUrl} className={classes.objectNameInner}>
                            <span className={classes.objectName}>{activeObjectName}</span>
                        </a>
                    )}
                </div>
                <div className={classes.fullWidth}>
                    {
                        contact && (
                            <a href="#" className={classes.contactUs} onClick={onClickHandler}>
                                <span>Jam i interesuar</span>
                            </a>
                        )
                    }
                </div>
            </div>
            {showContactModal && (
                <Modal
                    customBox="customBox"
                    onModalClose={onModalClose}
                    setShowModal={setShowContactModal}
                    isOpen={showContactModal}
                >
                    <Contact selectedObject={[]} />
                </Modal>
            )}
        </>
    );
};

export default Header;