import React, {useEffect, useRef, useState} from "react";
import {createUseStyles} from 'react-jss';
import modalStyles from './modal.style';
import {useOnClickOutside} from "./customHook/useOnClickOutside";
import useScreenSize from "../../hooks/useScreenSize";

const useStyles = createUseStyles((modalStyles));

const Modal = ({ children, onModalClose, setShowModal,  customBox, isOpen}) => {
    const classes = useStyles();
    const modalRef = useRef(null);
    const { orientation } = useScreenSize();

    useOnClickOutside(modalRef, () => setShowModal(false));

    return(
        <div className={`${classes.modalBox} ${isOpen ? 'active' : ''}`}>
            <div
                ref={modalRef} className={`${classes.box} ${customBox}`}>
                <span
                    className={`${classes.closeIcon} 
                    ${orientation === 'landscape-primary' ? classes.landscape : 'portrait'}`}
                    onClick={onModalClose}
                ></span>
                {children}
            </div>
        </div>
    );
};

Modal.defaultProps = {
    customBox: ''
}


export default Modal;