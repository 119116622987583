import React, {useCallback, useState} from "react";
import {createUseStyles} from 'react-jss';
import buildingPageStyles from "./buildingPage.styles";
import Modal from "../../components/Modal/Modal";
import Contact from "../../components/Contact/Contact";
import AppartmentsSlider from "./ApartmentsSlider";

const useStyles = createUseStyles(buildingPageStyles);

const BuildingModalDetails = ({ selectedObject }) => {
    const classes = useStyles();
    const acf_data = selectedObject && selectedObject[0]?.acf;
    const [showContactModal, setShowContactModal] = useState(false);

    const buildingGallery = selectedObject && selectedObject[0]?.acf?.gallery_buildings;

    const numberAsString = ['Zero', 'Një', 'Dy', 'Tre', 'Katër', 'Pesë', 'Gjashtë'];

    // Converting nr to string. Unused at the moment
    const findNumberIndexed = numberAsString.findIndex((value, index, obj
    ) => +index === +selectedObject[0]?.acf?.bedrooms_number);

    const onQuestionHandler = useCallback((e) => {
        e.preventDefault();
        setShowContactModal(true);
    }, []);

    const onModalClose = useCallback(() => {
        setShowContactModal(false);
    }, []);

    return(
        <>
            <div className={classes.modalDetailsWrapper}>
                <div className={classes.modalDetailsInner}>
                    <div className={classes.modalInnerContent}>
                        <h1>apartament me {+selectedObject[0]?.acf?.bedrooms_number} dhoma</h1>
                        <h2>Nr. i njësisë: {acf_data.unit_number}</h2>
                        {/*<h3>Të dhënat e apartamentit</h3>*/}
                        <div className={classes.apartmentView}>
                            <div>
                                <label htmlFor="">Kati</label>
                                <span>{acf_data.building_floor}</span>
                            </div>
                            <div>
                                <label htmlFor="">Sipërfaqe totale</label>
                                <span>{acf_data.area} &#13217;</span>
                            </div>
                            <div>
                                <label htmlFor="">Nr. i dhomave</label>
                                <span>{acf_data.bedrooms_number}</span>
                            </div>
                        </div>
                        <div className={classes.orientationBadge}>
                            {acf_data?.orientation?.url && (
                                <img src={acf_data.orientation.url} alt="" width={acf_data.orientation.sizes['thumbnail-width']}/>
                            )}
                        </div>
                    </div>
                    <div className={classes.modalOuterButtons}>
                        <div className={`${classes.modalInnerButton} ${classes.askForPrice}`}>
                            <a href="#" onClick={onQuestionHandler}>Pyet për çmimin</a>
                        </div>
                        <div className={classes.downloadPDF}>
                            <a href={acf_data.download_pdf} target="_blank">Shkarko PDF</a>
                        </div>
                    </div>
                </div>
                <AppartmentsSlider
                    buildingGallery={buildingGallery}
                />
            </div>
            <div className={classes.modalDisclaimer}>
                Vërejtje: Të gjitha materialet, dimensionet dhe vizatimet janë të përafërta. Llogaritja e sipërfaqes  së brendshme dhe të jashtme është plotësisht në përputhje me stadardet lokale të ndërtimit dhe si e tillë përfaqëson sipërfaqen neto të njësisë.
                <br/>
                Vërejtje: Të gjitha pamjet e banesës që shihen në vazhdim të  këti prezentimi janë  ide të punuara nga profesionistët tanë, si shembull për mundësinë e organizimit të banesës në fjalë. Pamjet  nuk nënkuptojnë si ofertë në çmimin bazë të banesës, mirëpo çdo pjesë e banesës mund te ofrohet nga ana jonë me marëveshje  të veçantë paraprake nëse keni interesim të blini ,,me çelës në dorë’’!
            </div>
            {showContactModal && (
                <Modal
                    customBox="customBox"
                    onModalClose={onModalClose}
                    setShowModal={setShowContactModal}
                    isOpen={showContactModal}>
                    <Contact selectedObject={selectedObject} />
                </Modal>
            )}
        </>
    );
};

export default BuildingModalDetails;