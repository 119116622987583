import React, { useEffect, useState, useCallback } from "react";
import ReactSlider from "react-slider";
import {createUseStyles} from 'react-jss';
import filterStyles from './filter.styles';
import {useBuildingDetailsContext} from "../../context/BuildingDetailsContext";
import {useBuildingsCat} from "../../context/BuildingCategoriesContext";
import useScreenSize from "../../hooks/useScreenSize";

const useStyles = createUseStyles(filterStyles);

const FilterSidebar = ({ primaryCategoryId, setBtnActiveEffect, setPrevActiveArrow, setNextActiveArrow }) => {
    const classes = useStyles();
    const {setFloorRangeNr, setBedroomNr, setAreaRangeNr, bedroomNr, floorRangeNr, showAllSelected,
        setShowAllSelected, areaRangeNr
    } = useBuildingDetailsContext();
    const { buildingsCat } = useBuildingsCat();
    const { orientation } = useScreenSize();

    const [bedroomsNumber, setBedroomsNumber] = useState(0);
    const [categoryFiltered, setCategoryFiltered] = useState([]);
    const [showFilter, setShowFilter] = useState('active');
    const [floorNumbers, setFloorNumbers] = useState(undefined);
    const [areaNumbers, setAreaNumbers] = useState([0, 0]);
    const [defaultFloorValue, setDefaultFloorValue] = useState([]);

    useEffect(() => {
        const primaryCategory = buildingsCat?.filter((category) => category.id === +primaryCategoryId);
        setCategoryFiltered(primaryCategory);
    }, [buildingsCat]);

    useEffect(() => {
        setShowAllSelected(false);
    }, []);

    useEffect(() => {
        if (categoryFiltered.length) {
            setFloorNumbers(parseInt(categoryFiltered[0]?.acf.floor_range));
            setAreaNumbers([parseInt(categoryFiltered[0]?.acf.area_range_min),
                parseInt(categoryFiltered[0]?.acf.area_range_max)])
        }
    }, [categoryFiltered]);

    useEffect(() => {
        setDefaultFloorValue([1, floorNumbers]);
    }, [floorNumbers]);

    useEffect(() => {
        const bedrooms = parseInt(categoryFiltered[0]?.acf.bedroom_numbers);
        if (categoryFiltered.length) {
            const countBedrooms = [...Array(+bedrooms)].map((e, i) => i);
            setBedroomsNumber(countBedrooms);
        }
    }, [categoryFiltered]);

    const onBedroomNumberClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowAllSelected(false);

        const { innerText } = e.target;

        const newList = [ ...bedroomNr ];
        const itemIndex  = newList.findIndex(item => item.number === +innerText);

        setBtnActiveEffect('test');

        setTimeout(() => {
            setBtnActiveEffect('');
            setPrevActiveArrow('activeArrows');
            setNextActiveArrow('nextActiveArrow');
        }, 3000);

        if (itemIndex > -1) {
            newList.splice(itemIndex, 1);
        } else {
            newList.push(({
                active: 'selected',
                number: +innerText
            }));
        }
        setBedroomNr(newList);

    }, [setBedroomNr, bedroomNr]);

    const onFloorRangeChange = useCallback((value) => {
        setShowAllSelected(false);
        setFloorRangeNr(value);
    }, []);

    const onAreaRangeChange = useCallback((value) => {
        setShowAllSelected(false);
        setAreaRangeNr(value);
    }, []);

    const onCheckboxChange = useCallback((e) => {
        e.preventDefault();

        setFloorRangeNr([1, 10]);
        setShowAllSelected(!showAllSelected);
        setBedroomNr([]);
        setAreaRangeNr([parseInt(categoryFiltered[0]?.acf.area_range_min),
            parseInt(categoryFiltered[0]?.acf.area_range_max)]);
    }, [showAllSelected, bedroomNr, areaRangeNr, categoryFiltered]);

    const onShowHideClick = useCallback((e) => {
        e.preventDefault();
        const name =  e.target.getAttribute('name');
        if (name === 'filters') {
            setShowFilter('active');
        } else {
            setShowFilter('');
        }
    }, [setShowFilter]);

    return(
        <>
            <div className={`${classes.filterContainer} ${showFilter} ${orientation === 'landscape-primary' ? 'landscape' : 'portrait'}`}>
                <div className={`${classes.showOrHideFilter} ${showFilter} ${orientation === 'landscape-primary' ? 'landscape' : 'portrait'}`}>
                    <div className={`${classes.svgFilterIcon} ${showFilter} ${orientation === 'landscape-primary' ? 'showOrHideFilter' : 'portrait'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                            <g id="Layer_32" data-name="Layer 32">
                                <path d="M33.5,19.14V12a1.5,1.5,0,0,0-3,0v7.14a6.07,6.07,0,0,0,0,11.74V52a1.5,1.5,0,0,0,3,0V30.88C39.52,29.34,39.52,20.67,33.5,19.14ZM32,28.07a3.07,3.07,0,0,1,0-6.13A3.07,3.07,0,0,1,32,28.07Z"/>
                                <path d="M53.5,32a6.07,6.07,0,0,0-4.56-5.87V12a1.5,1.5,0,0,0-3,0V26.13a6.07,6.07,0,0,0,0,11.74V52a1.5,1.5,0,0,0,3,0V37.87A6.07,6.07,0,0,0,53.5,32Zm-6,3.06a3.06,3.06,0,0,1,0-6.12A3.06,3.06,0,0,1,47.46,35.06Z"/>
                                <path d="M18.06,37V12a1.5,1.5,0,1,0-3,0V37a6.07,6.07,0,0,0,0,11.73V52a1.5,1.5,0,0,0,3,0V48.74A6.07,6.07,0,0,0,18.06,37Zm-1.5,8.94a3.07,3.07,0,0,1,0-6.13A3.07,3.07,0,0,1,16.56,45.94Z"/>
                            </g>
                        </svg>
                    </div>
                    <label
                        className={`${classes.labelFilter} ${orientation === 'landscape-primary' ? 'landscape' : 'portrait'} lblFilters`}
                        name="filters"
                        onClick={onShowHideClick}>
                        Filtro
                    </label>
                    <label
                        className={`${classes.labelHide} ${orientation === 'landscape-primary' ? 'landscape' : 'portrait'} lblHide`}
                        name="hide"
                        onClick={onShowHideClick}>
                        <div className={`${classes.closeIcon} ${orientation === 'landscape-primary' ? 'landscape' : 'portrait'}`}>
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                 viewBox="0 0 100.353 100.353" style={{ enableBackground: 'new 0 0 100.353 100.353' }} space="preserve">
                                <path style={{ fill: '#ffffff' }} d="M99.574,97.399L52.061,49.884L99.345,2.6c0.569-0.568,0.569-1.491,0-2.06
                                    c-0.569-0.568-1.49-0.568-2.059,0L50.002,47.824L2.721,0.54c-0.569-0.569-1.49-0.568-2.059,0c-0.569,0.569-0.569,1.491,0,2.06
                                    l47.281,47.284L0.428,97.399c-0.569,0.569-0.569,1.491,0,2.06c0.284,0.284,0.657,0.427,1.03,0.427s0.745-0.143,1.03-0.427
                                    l47.515-47.515l47.513,47.515c0.284,0.284,0.657,0.427,1.03,0.427s0.745-0.143,1.03-0.427
                                    C100.143,98.89,100.143,97.968,99.574,97.399z"/>
                            </svg>
                            Mbyll
                        </div>
                    </label>
                </div>
                <div className={`${classes.filterSidebar} ${showFilter} ${orientation === 'landscape-primary' ? 'landscape' : 'portrait'}`} >
                    <div className={`${classes.filterSection} ${showFilter} ${orientation === 'landscape-primary' ? classes.landscape : 'portrait'}`}>
                        <div className={`${classes.filterInner} ${orientation === 'landscape-primary' ? classes.filterInnerLandscape : 'portrait'}`}>
                            <h3>Nr. Dhomave</h3>
                            <div className={classes.filterDescription}>
                                {
                                    bedroomsNumber?.length && bedroomsNumber.map((bedroom, i) => (
                                        <div className={classes.filterItems}>
                                            <div className={classes.filterItem}>
                                                <a href="" onClick={onBedroomNumberClick}
                                                   className={bedroomNr.length && bedroomNr?.find(({ number }) => number === i) ? 'selected' : ''}
                                                >
                                                    <span>{i}</span>
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={`${classes.filterInner} ${orientation === 'landscape-primary' ? classes.filterInnerLandscape : 'portrait'}`}>
                            <div className={classes.sliderInner}>
                                <div className={classes.sliderSector}>
                                    <h3>Katet nga</h3>
                                </div>
                                <div className={classes.sliderSection}>
                                    {floorNumbers && (
                                        <ReactSlider
                                            className="horizontal-slider"
                                            thumbClassName="slider-thumb"
                                            trackClassName="slider-track"
                                            defaultValue={[1, floorNumbers]}
                                            value={floorRangeNr}
                                            min={1}
                                            max={floorNumbers}
                                            minDistance={1}
                                            onChange={onFloorRangeChange}
                                        />
                                    )}
                                    <div className={classes.sliderNumbers}>
                                        {floorRangeNr.length ? (
                                           <>
                                               <span>{floorRangeNr[0]}</span>
                                               <span>-</span>
                                               <span>{floorRangeNr[1]}</span>
                                           </>
                                        ) : (
                                            <>
                                                <span>{1}</span>
                                                <span>-</span>
                                                <span>{floorNumbers}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.filterInner} ${orientation === 'landscape-primary' ? classes.filterInnerLandscape : 'portrait'}`}>
                            <h3>Sipërfaqja nga</h3>
                            <div className={classes.sliderSection}>
                                {areaNumbers[0] !==0 && areaNumbers[1] !==0 && (
                                    <ReactSlider
                                        className="horizontal-slider"
                                        thumbClassName="slider-thumb"
                                        trackClassName="slider-track"
                                        defaultValue={areaNumbers}
                                        value={areaRangeNr}
                                        min={areaNumbers[0]}
                                        max={areaNumbers[1]}
                                        minDistance={1}
                                        onChange={onAreaRangeChange}
                                    />
                                )}
                                <div className={classes.sliderNumbers}>
                                    {areaRangeNr.length ? (
                                        <>
                                            <span>{areaRangeNr[0]} m<sup>2</sup></span>
                                            <span>-</span>
                                            <span>{areaRangeNr[1]} m<sup>2</sup></span>
                                        </>
                                    ) : (
                                        <>
                                            <span>{areaNumbers[0]} m<sup>2</sup></span>
                                            <span>-</span>
                                            <span>{areaNumbers[1]} m<sup>2</sup></span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.filterInner} ${orientation === 'landscape-primary' ? classes.filterInnerLandscape : 'portrait'}`}>
                            <div className={classes.checkBox}>
                                <a className={`${classes.hideCheckbox} ${showAllSelected ? 'selected' : ''}`} href="#" onClick={onCheckboxChange}>
                                    <i></i>
                                    <span>Reseto</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterSidebar;
