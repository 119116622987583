import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {createUseStyles} from 'react-jss';
import { useBuildingsCat } from "../../context/BuildingCategoriesContext";
import { useBuildingDetailsContext } from "../../context/BuildingDetailsContext";
import buildingPageStyles from "./buildingPage.styles";
import BuildingDetails from "../../components/buildings/BuildingDetails";
import './effectStyle.css';
import Card from "../../components/card/Card";

const useStyles = createUseStyles(buildingPageStyles);

const BuildingCategory = ({ id: buildingId, buildingData, filterById, btnActiveEffect, prevActiveArrow, nextActiveArrow }) => {
    const classes = useStyles();
    const { bedroomNr } = useBuildingDetailsContext();
    const [state, setState] = useState({ activeIndex: 0 });
    const [filterByBedrooms, setFilterByBedrooms] = useState([]);
    // const [filteredPage, setFilteredPage] = useState([]);

    const { buildingsCat, allSubCat } = useBuildingsCat();
    const filterBuildings = allSubCat.filter(({ parent }) => parent === +buildingId);

    // const filterByBedrooms = buildingData.filter(({ acf }) => +acf.bedrooms_number === bedroomNr[0]?.number);

    useEffect(() => {
        const filterBedrooms = buildingData.filter(({ acf }) => +acf.bedrooms_number === bedroomNr[0]?.number);
        setFilterByBedrooms(filterBedrooms);
    }, [buildingData]);

    const filteredPage = filterBuildings.findIndex(({ id }) => {
        const bedroomNrFiltered = filterByBedrooms && filterByBedrooms.length && filterByBedrooms[filterById?.taxonomy][1];
        return id === +bedroomNrFiltered;
    });

    useEffect(() => {
        if (filteredPage >= 0) {
            setState({
                activeIndex: filteredPage
            })
        }
    }, [filteredPage, setState]);

    const { activeIndex } = state;

    const onNextClick = () => {
        if (activeIndex === filterBuildings.length - 1) {
            return setState({
                ...state,
                activeIndex: 0
            })
        }

        setState({
            ...state,
            activeIndex: activeIndex + 1
        });
    }

    const onPrevClick = () => {
        if (activeIndex === 0) {
            return setState({
                ...state,
                activeIndex: filterBuildings.length - 1
            })
        }
        setState({
            ...state,
            activeIndex: activeIndex - 1
        });
    }

    return(
      <div className={classes.buildingCatContainer} id='buildings-info'>
          {
              filterBuildings.length ? (
                  filterBuildings.map((building, index) => (
                      <BuildingDetails
                          buildingActiveId={`${activeIndex === index && building.id}`}
                          active={`${activeIndex === index ? 'active': ''}`}
                          image={building?.acf.building_image.url}
                          building={building}
                          buildingData={buildingData}
                          filterById={filterById}
                      />
                  ))
              ) : (
                  // Heke kete kusht, kur tjen kategorite me child
                  filterById && (
                      <BuildingDetails
                          active='active'
                          image={filterById?.acf?.building_image?.url}
                          filterBuildings={[]}
                      />
                  )
              )
          }
          <div className={classes.buildingCatIconNextPrev}>
              <div className={classes.btnPrevOuter}>
                  {!!(btnActiveEffect || prevActiveArrow) && (
                      <span className={classes.roundObjectText}>Rrotullo objektin</span>
                  )}
                  <button
                      className={`${classes.nextPrevContainer} ${classes.prev} ${btnActiveEffect !== '' ? 'btnActiveEffect' : prevActiveArrow}`}
                      onClick={onPrevClick}
                  ></button>
              </div>
              <div className={classes.btnRightOuter}>
                  {!!(btnActiveEffect || prevActiveArrow) && (
                      <span className={classes.roundObjectText}>Rrotullo objektin</span>
                  )}
                  <button
                      className={`${classes.nextPrevContainer} ${classes.next} ${btnActiveEffect !== '' ? 'btnActiveEffect' : nextActiveArrow}`}
                      onClick={onNextClick}
                  ></button>
              </div>
          </div>
          <Card />
      </div>
    );
};

export default BuildingCategory;