import React, { useState, useCallback } from 'react';
import Slider from 'react-slick';
import {createUseStyles} from "react-jss";
import buildingPageStyles from "./buildingPage.styles";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const useStyles = createUseStyles(buildingPageStyles);

const ArrowPrev = ({ className, onClick }) => (
    <div className={className} onClick={onClick} />
);

const ArrowNext = ({ className, onClick }) => (
    <div className={className} onClick={onClick} />
);


const AppartmentsSlider = ({ buildingGallery }) => {
    const classes = useStyles();
    const [currentImage, setCurrentImage] = useState('');

    const handleClickImage = useCallback((e, image) => {
        e && e.preventDefault();
        setCurrentImage(image.url);
    }, []);

    const handleCloseModal = useCallback(() => {
        setCurrentImage('');
    }, [setCurrentImage]);

    const onPrevClick = (e) => {
        console.log(e);
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        className: 'slider-custom',
        prevArrow: <ArrowPrev onClick={onPrevClick} />,
        nextArrow: <ArrowNext onClick={onPrevClick} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return(
        <div className={classes.modalDetailsGallery}>
            <Slider {...settings}>
                {buildingGallery?.map((gallery) => {
                    return(
                        <div className={classes.parentImageGallery}>
                            <div
                                style={{
                                backgroundImage: `url(${gallery.url})`,
                            }}
                                 className={classes.imageSlider}
                                onClick={e => handleClickImage(e, gallery)}
                            >

                            </div>
                        </div>
                    );
                })}
            </Slider>
            {currentImage &&
                <div style={{  zIndex: '999999', }}>
                    <Lightbox
                        mainSrc={currentImage}
                        onCloseRequest={handleCloseModal}
                    />

                </div>
            }
        </div>
    )
};

export default AppartmentsSlider