import arrowRight from './images/arrow-right.svg'

export default () => ({
    popupPrimary: {
        position: "absolute",
        zIndex: 20,
        maxHeight: "0",
        width: "192px",
        opacity: 1,
        transform: "translate(-50%, -100%)",
        marginLeft: "-2px",
        marginTop: "-25px",
        transition: "opacity 0.5s ease 0.3s",
        backgroundColor: "rgba(255, 255, 255, 1)",
        overflow: "hidden",
        '&.active': {
            height: 'auto',
            width: '200px',
            overflow: "visible",
            boxShadow: "2px 2px 2px 2px rgb(0 0 0 / 40%)",
            zIndex: 200,
            display: 'table'
        },
        '&.bottom': {
            transform: "translate(-50%, 0)",
            marginTop: "25px",
            '&:after': {
                top: "0", bottom: "auto", marginTop: "-2px"
            }
        },
        '&:after': {
            display: "block",
            content: '""',
            height: "17px",
            width: "17px",
            transform: "rotate(\n45deg) translate(-50%, 0)",
            backgroundColor: "rgba(255, 255, 255, 1)",
            position: "absolute",
            bottom: "-14px",
            left: "50%",
            zIndex: 5
        }
    },
    comingSoon: {
        display: 'block',
        padding: 20,
        textAlign: 'center'
    },
    popoverDescription: {
        display: "block",
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    popoverImage: {
        display: "block",
        width: "100%",
        height: "150px",
        overflow: "hidden",
        position: 'relative',
        zIndex: '99',
        '& img': {
            width: "100%",
        }
    },
    popoverLink: {
        padding: "0 5px",
        marginBottom: "6px",
        zIndex: 10,
        position: "relative"
    },
    popoverLinkInner: {
        display: "block",
        width: "100%",
        backgroundColor: "transparent",
        margin: "4px 0",
        cursor: "pointer",
        background:
            `url(${arrowRight}) no-repeat center right transparent`,
        backgroundSize: "24px",
        paddingTop: 10,
        paddingBottom: 10,
        '& a': {
            color: "#14171c",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            textDecoration: 'none',
            textTransform: 'uppercase',
            display: 'block',
            padding: '0 20px',
            width: 'auto'
        }
    },
    apartmentView: {
        margin: "15px 0",
        display: "block",
        padding: "0 15px",
        borderBottom: "1px solid rgba(41, 41, 41, 0.18)",
        '& div': {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            '& label': {
                fontWeight: 500
            }
        }
    },
    apartmentLink: {
        textTransform: 'none'
    },

    bDisplay: {

    },

    popoverObjName: {
        position: "absolute",
        top: "0",
        left: "0",
        color: "#14171c",
        cursor: "pointer",
        display: "block",
        alignItems: "center",
        textTransform: "uppercase",
        justifyContent: "center",
        textDecoration: "none",
        textAlign: "center",
        width: "100%",
        background: "rgb(255 255 255 / 80%)",
        paddingTop: "5px",
        paddingBottom: "5px"
    }
});