import React, {useRef, useState, useEffect, useCallback} from "react";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {createUseStyles} from 'react-jss';
import buildingsStyle from "./buildings.styles";
import FloorPolygon from "./FloorPolygon";
import ReactSlider from "react-slider";
import useScreenSize from "../../hooks/useScreenSize";


const useStyles = createUseStyles(buildingsStyle);


const BuildingDetails = ({ buildingActiveId, active, image, building, buildingData, filterById }) => {
    const classes = useStyles();
    const transformComponentRef = useRef(null);
    const [width, setWidth]   = useState(window.innerWidth);
    const { orientation } = useScreenSize();
    const [imgHeight, setImgHeight] = useState(100);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        // setOrientation(window.screen.orientation.type);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    const [scale, setScale] = useState(width > 1600 ? 1 : 0.8);
    const [postFiltered, setPostFiltered] = useState([]);

    const imageWidth = building?.acf?.image_width || building?.acf?.building_image.width;
    const imageHeight = building?.acf?.image_height || building?.acf?.building_image.height;

    useEffect(() => {
        if (buildingActiveId) {
            const filterPost = buildingData?.filter((post) => post[filterById[0].taxonomy]?.includes(+buildingActiveId));
            console.log('filterPost::', filterPost);
            setPostFiltered(filterPost);
        }
    }, [buildingData, setPostFiltered, filterById, buildingActiveId]);

    // const ref = useRef<ReactZoomPanPinchRef | null>(null);

    // console.log('transformComponentRef', transformComponentRef);

    // useEffect(() => {
    //     if (transformComponentRef.current) {
    //         console.log('hellooo', transformComponentRef.current.state.positionX);
    //         transformComponentRef.current.setTransform(
    //             //calculate x and y
    //             transformComponentRef.current.state.positionX,
    //             transformComponentRef.current.state.positionY,
    //             // zoom
    //         );
    //     }
    // }, []);

    // const onButtonTest = () => {
    //     if (transformComponentRef.current) {
    //         console.log('hellooo', transformComponentRef.current.state);
    //         if (transformComponentRef.current.state.positionX >= -1100) {
    //             transformComponentRef.current.setTransform(
    //                 //calculate x and y
    //                 transformComponentRef.current.state.positionX - 60,
    //                 transformComponentRef.current.state.positionY,
    //                 // zoom
    //             );
    //         }
    //     }
    // }

    // const onBUttonPrev = () => {
    //     if (transformComponentRef.current) {
    //         console.log('hellooo', transformComponentRef.current.state.positionX);
    //         if (transformComponentRef.current.state.positionX < 0) {
    //             transformComponentRef.current.setTransform(
    //                 //calculate x and y
    //                 transformComponentRef.current.state.positionX + 60,
    //                 transformComponentRef.current.state.positionY,
    //                 // zoom
    //             );
    //         }
    //     }
    // }

    const onSliderChange = useCallback((event) => {

        if (transformComponentRef.current) {

            if (event >= -1100) {
                transformComponentRef.current.setTransform(
                    //calculate x and y
                    -event,
                    transformComponentRef.current.state.positionY,
                    // zoom
                );
            } else {
                transformComponentRef.current.setTransform(
                    //calculate x and y
                    event,
                    transformComponentRef.current.state.positionY,
                    // zoom
                );
            }
        }
    }, []);


    return(
        <div className={`${classes.containerDiv} ${classes.buildingContainer} ${active === 'active' ? 'fade-in' : 'fade-out'}`}>
            <div className={`${classes.buildingOuter} ${active}`} style={{ backdropFilter: 'blur(5px)' }}>
                <div className={classes.overlay} style={{ backgroundImage: `url(${image})` }}></div>
                <TransformWrapper
                    ref={transformComponentRef}
                    onZoomStop={(e) => {
                        setScale(e.state.scale);
                    }}
                    initialScale={scale}
                    minScale={0.7}
                    maxScale={2.5}
                    doubleClick={{
                        disabled: true
                    }}
                    wheel={{
                        wheelDisabled: true,
                    }}
                >
                    {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => (
                        <React.Fragment>
                            <TransformComponent
                                wrapperStyle={{
                                    maxWidth: "100%",
                                    width: "100%",
                                    maxHeight: '100vh'
                                }}
                            >
                                <div>
                                    <img
                                        // className="complex_img show"
                                        className={`complex_img show ${orientation === 'landscape-primary' ? classes.imgLandscape : 'portrait'}`}
                                         id="image-id"
                                         src={image}
                                         alt="#"
                                         style={{
                                             height: `${imgHeight}%`
                                         }}
                                    />
                                    <svg
                                        id="Layer_1"
                                        data-name="Layer 1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns="http://www.w3.org/1999/xlink"
                                        viewBox={`0 0 ${imageWidth} ${imageHeight}`}
                                        style={{
                                            height: `${imgHeight}%`
                                        }}
                                        className={`${orientation === 'landscape-primary' ? classes.imgLandscape : 'portrait'}`}
                                    >
                                        {
                                            postFiltered.map(post => (
                                                <FloorPolygon
                                                    classes={classes}
                                                    post={post}
                                                />
                                            ))
                                        }
                                    </svg>
                                </div>
                            </TransformComponent>
                        </React.Fragment>
                    )}
                </TransformWrapper>
                <div className={`${classes.scrollLeftToRightSection} ${classes.scrollLRDetails} ${orientation === 'landscape-primary' ? 'landscape' : 'portrait'}`}>
                    <div className={classes.scrollLRInner}>
                        <h2 className={classes.navigateSideText}>Navigo anash</h2>
                        <div className={classes.sliderSection}>
                            <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                defaultValue={0}
                                min={0}
                                max={1100}
                                minDistance={60}
                                onChange={onSliderChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuildingDetails;