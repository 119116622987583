import React, {useCallback, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BuildingCategory from "./BuildingCategory";
import Header from "../../components/header/Header";
import FilterSidebar from "../../components/FilterSidebar/FilterSidebar";
import {BuildingDetailsContext} from "../../context/BuildingDetailsContext";
import Popover from "../../components/Popover/Popover";
import useComponentVisible from "../../hooks/useComponentVisible";
import {useBuildingsCat} from "../../context/BuildingCategoriesContext";
import {useHeader} from "../../context/HeaderContext";
import getCoordinates from "../../utils/getCoordinates";
import Modal from "../../components/Modal/Modal";
import BuildingModalDetails from "./BuildingModalDetails";
import Loader from "../../components/Loader/Loader";

const BuildingsPage = () => {
    const { id } = useParams();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);
    const { buildingsCat } = useBuildingsCat();
    const [loader, setLoader] = useState(true);
    const { setActiveId } = useHeader();
    const [buildingData, setBuildingData] = useState([]);
    const [bedroomNr, setBedroomNr] = useState([]);
    const [floorRangeNr, setFloorRangeNr] = useState([]);
    const [areaRangeNr, setAreaRangeNr] = useState([]);
    const [showAllSelected, setShowAllSelected] = useState(false);
    const [showPopup, setShowPopup] = useState({});
    const [bottomClass, setBottomClass] = useState('');
    const [elementId, setElementId] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState([]);
    const [apartmentId, setApartmentId] = useState(undefined);
    const [filterById, setFilterById] = useState();
    const [buildingName, setBuildingName] = useState('');
    const [btnActiveEffect, setBtnActiveEffect] = useState('');
    const [prevActiveArrow, setPrevActiveArrow] = useState('');
    const [nextActiveArrow, setNextActiveArrow] = useState('');

    // useEffect(() => {
    //     axios.get(`/wp-json/wp/v2/buildings?buildings_name=${id}&per_page=200`).then((res) => {
    //         setBuildingData(res.data);
    //     }).catch();
    // }, []);

    // const filterById = buildingsCat.filter(({ id }) => id === id);

    useEffect(() => {
        setFilterById(buildingsCat.filter((obj) => obj.id === +id));
    }, [buildingsCat, id]);

    // const buildingName = filterById?.taxonomy === 'object_a_name' ? 'object_a'
    //     : filterById?.taxonomy === 'object_b_name' ? 'object_b' : 'object_c';

    // useEffect(() => {
    //     if (filterById !== undefined && filterById.length) {
    //         console.log('filterById::', filterById);
    //         const bName = filterById[0]?.taxonomy === 'object_a_name' ? 'object_a'
    //             : filterById[0]?.taxonomy === 'object_b_name' ? 'object_b' : 'object_c';
    //         setBuildingName(bName);
    //     }
    // }, [filterById, buildingsCat]);

    // New API call for Object B new
    useEffect(() => {
        if (filterById !== undefined && filterById.length) {
            console.log('filterById::', filterById);
            const bName = filterById[0]?.taxonomy === 'object_a_name' ? 'object_a'
                : filterById[0]?.taxonomy === 'object_b_new_name' ? 'object_b_new' : 'object_c';
            setBuildingName(bName);
        }
    }, [filterById, buildingsCat]);

    useEffect(() => {
        setActiveId(id);
    }, [setActiveId, id]);

    useEffect(() => {
        setLoader(true);

        if(buildingName) {
            axios.get(`https://www.monopolcity.com/wp-json/wp/v2/${buildingName}?per_page=900`).then((res) => {
                setBuildingData(res.data);
                if (res.data.length) {
                    setLoader(false);
                }
            }).catch();
        }
    }, [buildingName, setLoader]);

    useEffect(() => {
        if (selectedObject[0]?.id !== undefined && apartmentId !== undefined) {
            if (selectedObject[0]?.id === apartmentId) {
                setShowModal(true);
            }
        }
    }, [selectedObject, apartmentId]);

    useEffect(() => {
        if (elementId) {
            setSelectedObject(buildingData.filter(({ id }) => id === +elementId));
        }
    }, [elementId, buildingData]);

    useEffect(() => {
        if (apartmentId) {
            setSelectedObject(buildingData.filter(({ id }) => id === apartmentId));
        }
    }, [apartmentId, buildingData]);

    const onApartamentClick = useCallback((event) => {
        event.preventDefault();
        if (!showAllSelected) {
            getCoordinates(event, setElementId, setShowPopup, setBottomClass, setIsComponentVisible);
        }

    }, [showAllSelected]);

    const contextValue = {
        bedroomNr,
        setBedroomNr,
        floorRangeNr,
        setFloorRangeNr,
        areaRangeNr,
        setAreaRangeNr,
        showAllSelected,
        setShowAllSelected,
        onApartamentClick
    }

    const onHandleModalClose = () => {
        setShowModal(!showModal);
    };

    return(
        <>
            {loader ? (
                <Loader />
            ) : (
              <>
                  <Header
                      contact
                      showHamburgerMenu={false}
                  />
                  <BuildingDetailsContext.Provider value={contextValue}>
                      <BuildingCategory
                          id={id}
                          buildingData={buildingData}
                          filterById={filterById}
                          btnActiveEffect={btnActiveEffect}
                          prevActiveArrow={prevActiveArrow}
                          nextActiveArrow={nextActiveArrow}
                      />
                      <FilterSidebar
                          primaryCategoryId={id}
                          setBtnActiveEffect={setBtnActiveEffect}
                          setPrevActiveArrow={setPrevActiveArrow}
                          setNextActiveArrow={setNextActiveArrow}
                      />
                      <div ref={ref}>
                          {isComponentVisible && (
                              <Popover
                                  parentId={id}
                                  bottomClass={bottomClass}
                                  showPopup={showPopup}
                                  isComponentVisible={isComponentVisible}
                                  selectedObject={selectedObject}
                                  buildingDisplay={false}
                                  setShowModal={setShowModal}
                                  setApartmentId={setApartmentId}
                              />
                          )}
                      </div>
                      {selectedObject && (
                          showModal && (
                              <Modal
                                  onModalClose={onHandleModalClose}
                                  isOpen={showModal}
                                  // setShowModal={setShowModal}
                              >
                                  <BuildingModalDetails selectedObject={selectedObject} />
                              </Modal>
                          )
                      )}
                  </BuildingDetailsContext.Provider>
              </>
            )}
        </>
    );
};

export default BuildingsPage