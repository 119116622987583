import { useState, useEffect } from 'react';

export default function useScreenSize() {
    const [orientation, setOrientation] = useState('');

    const updateDimensions = () => {
        if (window.innerHeight > window.innerWidth)
            setOrientation('portrait');
        else
            setOrientation('landscape-primary');
    };

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return { orientation };
}