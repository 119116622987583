export default () => ({
    filterContainer: {
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,

        '@media(max-width: 767px)': {
            height: 0,
        },
        '& .lblFilters': {
            display: 'block',
            paddingTop: 4,
            width: '100%',
            paddingRight: 5,

            '@media(max-width: 767px)': {
                fontSize: '1.5em',
                paddingTop: 0
            }
        },
        '& .lblHide': {
            display: 'none'
        },
        '&.active': {
            position: 'absolute',
            left: 0,
            bottom: 0,

            '& .lblFilters': {
                display: 'none'
            },
            '& .lblHide': {
                display: 'block',
                paddingTop: 4,
                width: '100%',
                position: 'relative',
                '@media(max-width: 767px)': {
                    lineHeight: '40px'
                }
            },

            '@media(max-width: 767px)': {
                height: '100%',
                zIndex: 9999,
                position: 'absolute'
            },
        },
    },
    filterSidebar: {
        WebkitBackdropFilter: "blur(4px)",
        overflowX: "auto",
        display: "flex",
        transition: "all 0.4s ease 0s",
        width: "100%",
        backdropFilter: "blur(6px)",
        background: "rgb(51 51 51 / 80%)",
        position: 'relative',
        zIndex: 9,

        '&.landscape': {
            '@media(max-width: 767px)': {
                height: '100%',
            }
        },

        '&.active': {
            '&.portrait': {
                '@media(max-width: 767px)': {
                    height: 'calc(100% - 50px)',
                    overflow: 'hidden'
                }
            }
        }
    },
    filterSection: {
        height: 0,
        overflow: "hidden",
        transition: "0.4s",
        width: "100%",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        columnGap: '30px',
        '@media(max-width:992px)': {
            justifyContent: 'space-between'
        },

        '&.portrait': {
            '@media(max-width: 767px)': {
                flexDirection: 'column',
                justifyContent: 'initial'
            }
        },

        '&.active': {
            maxWidth: "100%",
            transition: "all 0.4s ease 0.4s",
            paddingTop: "12px",
            paddingBottom: "12px",
            height: "85px",
            '@media(max-width:992px)': {
                height: '100%',
                marginBottom: 20
            }
        }
    },
    filterInner: {
        // padding: "0 30px 0",
        overflow: "hidden",
        minWidth: "20%",
        flex: '1 0 auto',
        '@media(max-width: 992px)': {
            padding: '0 20px 0',
            marginBottom: 20
        },
        '@media(max-width: 100px)': {
            padding: '0 10px 0'
        },

        '@media(max-width: 767px)': {
            flex: 'initial',
            overflow: 'initial',
            marginTop: 30
        },

        '& h3': {
            margin: "0 7px 15px",
            color: "#eee",
            textTransform: "uppercase",
            fontWeight: 300,
            '@media(max-width:992px)': {
                fontSize: 16,
                fontWeight: 300
            }
        },
        '& .slider-thumb': {
            border: "1px solid #d3b298",
            background: "#d3b298",
            fontWeight: "normal",
            color: "#d3b298",
            borderRadius: "50%",
            width: "22px",
            height: "22px",
            top: "-11px",
            userSelect: "none",
            outline: "none"
        },
        '& .slider-track': {
            left: "0px",
            right: "0px",
            height: "2px",
            background: "#d3b298",
            '&.slider-track-0': {
                background: "rgba(216, 216, 216, 0.3)"
            },
            '&.slider-track-2': {
                background: "rgba(216, 216, 216, 0.3)"
            }
        },
    },
    filterDescription: {
        display: "flex",
        overflow: "hidden",
        overflowX: "auto",

        '@media(max-width: 767px)': {
            height: '100%',
            zIndex: 9999,
            flexWrap: 'wrap',
            display: ["-webkit-box", "-moz-box", "-ms-flexbox", "-webkit-flex"],
            WebkitFlexFlow: "row wrap",
            justifyContent: "space-around"
        }
    },
    filterItems: {
        display: "flex",
        overflow: "hidden",
        overflowX: "auto",

        '@media(max-width: 767px)': {
            width: '50%',
            marginBottom: 10
        },
    },
    filterItem: {
        flex: 1,

        '@media(max-width: 767px)': {
            width: '100%',
            marginRight: 20
        },

        '& a': {
            color: "#fff",
            display: "flex",
            textDecoration: "none",
            transition: "all .4s ease 0s",
            fontSize: "16px",
            border: "1px solid #fff",
            height: "40px",
            width: "40px",
            overflow: "hidden",
            margin: "0 7px 0",
            justifyContent: "center",
            alignItems: "center",
            '@media(max-width:767px)': {
                fontWeight: 300,
                display: "block",
                width: "130px",
                height: "30px",
                textAlign: "center",
                fontSize: "17px",
                lineHeight: "30px"
            },

            '@media(max-width: 767px)': {
                width: '100%',
                margin: 0
            },

            '& span': {
                display: 'inline-block'
            },
            '&.selected': {
                color: "#fff",
                height: "34px",
                width: "34px",
                border: "solid 4px #3358ad",
                backgroundColor: "#7698ea",

                '@media(max-width: 767px)': {
                    width: '100%',
                    height: 30,
                },
            }
        }
    },
    sliderSection: {
        float: 'left',
        width: '100%',
        marginTop: 15
    },
    sliderInner: {
        padding: '0 14px',
        '@media(max-width: 1200px)': {
            padding: 0
        },
    },
    sliderSector: {
        marginBottom: "10px",
        width: "100%",
        '& h3': {
            textTransform: "uppercase",
            color: "#fff",
            fontSize: "20px",
            whiteSpace: "nowrap",
            fontWeight: 300,
            '@media(max-width:992px)': {
                fontSize: 16
            }
        }
    },
    sliderNumbers: {
        textAlign: "center",
        color: "#fff",
        fontSize: "12px",
        margin: "12px 0 0",

        '@media(max-width:767px)': {
            fontSize: 16
        }
    },
    checkBox: {
        display: "flex",
        overflow: "hidden",
        overflowX: "auto"
    },
    hideCheckbox: {
        color: "#fff",
        display: "flex",
        textDecoration: "none",
        transition: "all .4s ease 0s",
        fontSize: "16px",
        height: "42px",
        width: "100px",
        overflow: "hidden",
        margin: "0 7px 0",
        justifyContent: "center",
        alignItems: "center",
        '& i': {
            width: "20px",
            height: "20px",
            display: "inline-block",
            position: "relative",
            marginRight: "10px",
            border: "1px solid #aaa",
        },
        '&.selected': {
            height: 42,
            backgroundColor: 'transparent',
            width: 100,
            border: '0 none',
            '& i': {
                border: 'solid 1px #fff',
                backgroundColor: '#7698ea',
                '&:after': {
                    border: "2px solid #fff",
                    borderColor: "transparent #fff #fff transparent",
                    width: "5px",
                    height: "10px",
                    margin: "0 6px",
                    display: "block",
                    content: '""',
                    transform: "rotate(\n45deg\n)"
                }
            }
        },
        '& span': {
            display: 'inline-block',
            color: '#fff'
        }
    },
    showOrHideFilter: {
        borderRadius: "0 5px 0 0",
        color: "#fff",
        WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",
        backgroundColor: "#c89e68",
        display: "flex",
        justifyContent: "center",
        textTransform: "uppercase",
        transition: "all 0.4s ease 0s",
        overflow: "hidden",
        textAlign: "center",

        '@media(min-width: 767px)': {
            width: "70px",
            height: "24px",
            fontSize: 12
        },

        '@media(max-width:767px)': {
            width: "80px",
            // background: "red",
            fontSize: "12px",
            height: "25px",
            bottom: "inherit",
            left: "0",
            position: "fixed",
            zIndex: 9,
            top: "110px",
            borderRadius: 0,
            padding: 10
        },

        '&.landscape': {
            '@media(max-width:992px)': {
                zIndex: 99
            }
        },



        '&.active': {
            fontSize: "12px",
            width: "63px",
            height: "24px",
            WebkitBackdropFilter: "blur(4px)",
            backdropFilter: "blur(4px)",
            backgroundColor: "#c89e68",
            top: 'initial',

            '@media(max-width: 767px)': {
                position: 'absolute',
                width: "100%",
                height: "50px",
                bottom: 0,
                left: 0,
                borderRadius: 0,
                padding: 0
            },
        },
    },
    svgFilterIcon: {
        width: 24,
        marginTop: 3,
        marginLeft: 3,

        '&.active': {
            display: 'none'
        },

        '& svg': {
            fill: '#fff'
        }
    },

    landscape: {
        '@media(max-width: 992px)': {
            gap: '10px',
            alignItems: 'flex-start'
        }
    },

    filterInnerLandscape: {
        '@media(max-width: 992px)': {
            padding: '0 10px 0'
        }
    },

    closeIcon: {
        margin: '0 auto',
        position: 'relative',

        '@media(max-width: 767px)': {
            width: 100,
            fontSize: '16px',
        },
        '& svg': {
            width: 15,
            height: 15,
            float: 'right',
            paddingTop: 14,
            display: 'none',

            '@media(max-width: 767px)': {
                display: 'block'
            },
        },

        '&.landscape': {
            '& svg': {
                '@media(max-width: 992px)': {
                    width: 13
                },
            }
        }
    }
});