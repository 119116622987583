import React, {  useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { BuildingCategoriesContext } from "./context/BuildingCategoriesContext";
import HomePage from "./pages/home/HomePage";
import BuildingsPage from "./pages/buildings/BuildingsPage";
import axios from "axios";
import {HeaderContext} from "./context/HeaderContext";
import './App.css';
import useScreenSize from "./hooks/useScreenSize";

const App = () => {
    const [buildingsCat, setBuildingsCat] = useState([]);
    const { orientation } = useScreenSize();
    const [activeId, setActiveId] = useState('');
    const [objectA, setObjectA] = useState([]);
    const [objectB, setObjectB] = useState([]);
    const [objectC, setObjectC] = useState([]);
    const [subCatObjA, setSubCatObjA] = useState([]);
    const [subCatObjB, setSubCatObjB] = useState([]);
    const [subCatObjC, setSubCatObjC] = useState([]);
    const [allSubCat, setAllSubCat] = useState([]);
    const [loader, setLoader] = useState(true);
    const [activeObjectName, setActiveObjectName] = useState('');

    const updateDimensions = () => {
        // setOrientation(window.screen.orientation.type);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        axios.get('https://www.monopolcity.com/wp-json/wp/v2/object_a_name').then((res) => {
            setObjectA(res.data.filter(({ parent }) => parent === 0));
            setSubCatObjA(res.data.filter(({ parent }) => parent !== 0))
        }).catch();
    }, []);

    // Old Objekti B categories
    // useEffect(() => {
    //     axios.get('https://www.monopolcity.com/wp-json/wp/v2/object_b_name').then((res) => {
    //         setObjectB(res.data.filter(({ parent }) => parent === 0));
    //         setSubCatObjB(res.data.filter(({ parent }) => parent !== 0))
    //     }).catch();
    // }, []);

    // New API call for Object B
    useEffect(() => {
        axios.get('https://www.monopolcity.com/wp-json/wp/v2/object_b_new_name').then((res) => {
            setObjectB(res.data.filter(({ parent }) => parent === 0));
            setSubCatObjB(res.data.filter(({ parent }) => parent !== 0))
        }).catch();
    }, []);

    useEffect(() => {
        axios.get('https://www.monopolcity.com/wp-json/wp/v2/object_c_name').then((res) => {
            setObjectC(res.data.filter(({ parent }) => parent === 0));
            setSubCatObjC(res.data.filter(({ parent }) => parent !== 0))
        }).catch();
    }, []);

    useEffect(() => {
        setBuildingsCat([...objectA, ...objectB, ...objectC]);
        if (buildingsCat.length) {
            setLoader(false);
        }
    }, [setBuildingsCat, objectA, objectB, objectC, setLoader]);

    useEffect(() => {
        setAllSubCat([...subCatObjA, ...subCatObjB, ...subCatObjC])
    }, [setAllSubCat, subCatObjA, subCatObjB]);

    const categoriesContextValues = {
        buildingsCat,
        allSubCat,
        setBuildingsCat: setBuildingsCat,
        loader,
        setLoader
    };

    const headerContextValues = {
        activeId,
        setActiveId,
        activeObjectName,
        setActiveObjectName
    }

  return (
      <BuildingCategoriesContext.Provider value={categoriesContextValues}>
          <HeaderContext.Provider value={headerContextValues}>
              <div className={`${orientation === 'landscape-primary' ? 'landscape' : 'portrait'} App`}>
                  <Routes>
                      <Route exact path="/" element={
                          <HomePage />
                      }/>
                      <Route path="/buildings/:id" element={
                          <BuildingsPage />
                      } />
                      <Route path="/apartment/:id" element={
                          <BuildingsPage />
                      } />
                  </Routes >
              </div>
          </HeaderContext.Provider>
      </BuildingCategoriesContext.Provider>
  );
}

export default App;
