import React, {useCallback, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import {  Link, useLocation  } from "react-router-dom";
import { createUseStyles } from 'react-jss';
import popoverStyles from "./popover.styles";
// import { Link } from "react-router-dom";
import {parseQueryString} from "../../utils/utils";

const useStyles = createUseStyles((popoverStyles));

const CustomPopover = ({ parentId, bottomClass, showPopup, isComponentVisible, selectedObject, buildingDisplay,
setShowModal, setApartmentId, homePopover }) => {
    const classes = useStyles();
    const location = useLocation();

    const acf_data = selectedObject && selectedObject[0]?.acf;
    const queryParams = useMemo(
        () => parseQueryString(location.search),
        [location]
    );

    useEffect(() => {
        if (queryParams?.apartment) {
            setApartmentId(+queryParams.apartment);
        }
    }, [queryParams]);

    const onFloorPlanHandler = useCallback((event) => {
        setShowModal(true);
    }, []);

    return (
        <div className={`${classes.popupPrimary} hover_popup ${bottomClass} active`}
             style={{
                 top: showPopup.top,
                 left: showPopup.left,
                 opacity: isComponentVisible ? 1 : 0
             }}
        >
                <div className={classes.popoverDescription}>
                    {acf_data && (
                        buildingDisplay ? (
                            <>
                                <div className={classes.popoverImage}>
                                    {acf_data?.building_image && (
                                        <div className={classes.bDisplay}>
                                            <Link
                                                to={`/buildings/${selectedObject[0]?.id}`}
                                                className={classes.popoverObjName}
                                            >
                                                {selectedObject[0]?.name}
                                            </Link>
                                            <img
                                                src={acf_data?.building_image.sizes.thumbnail}
                                                alt=""
                                            />
                                        </div>
                                    )}

                                </div>
                                <div className={classes.popoverLink}>
                                    <div className={classes.popoverLinkInner}>
                                        {
                                            acf_data?.not_started_yet ? (
                                                <span className={classes.popoverLinkInner}>
                                                    {selectedObject[0]?.name}
                                                </span>
                                            ) : (
                                                <Link
                                                    to={`/buildings/${selectedObject[0]?.id}`}>
                                                    {/*{selectedObject[0]?.name}*/}
                                                   Zgjidh Banesën
                                                </Link>
                                            )
                                        }

                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {acf_data?.not_started_yet ? (
                                    <div className={classes.comingSoon}>
                                        Së shpejti....
                                    </div>
                                ) : acf_data?.business_space ? (
                                    <div className={classes.comingSoon}>
                                        Hapësirë afariste
                                    </div>
                                ) : acf_data?.is_solded ? (
                                    <div className={classes.comingSoon}>
                                        E shitur.
                                    </div>
                                ) : (
                                    <>
                                        <div className={classes.apartmentView}>
                                            <div>
                                                <label htmlFor="">Kati</label>
                                                <span>{acf_data.building_floor}</span>
                                            </div>
                                            <div>
                                                <label htmlFor="">Sip. totale</label>
                                                <span>{acf_data.area}</span>
                                            </div>
                                            <div>
                                                <label htmlFor="">Nr. i dhomave</label>
                                                <span>{acf_data.bedrooms_number}</span>
                                            </div>
                                        </div>
                                        <div className={classes.popoverLink}>
                                            <div className={`${classes.popoverLinkInner} ${classes.apartmentLink}`}>
                                                <Link
                                                    to={`/buildings/${parentId}?apartment=${selectedObject[0].id}`}
                                                    onClick={onFloorPlanHandler}
                                                    className={classes.exploreLink}
                                                >
                                                    Shih planin
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </>
                        )
                    )}
                </div>
        </div>
    );
};

CustomPopover.defaultProps = {
    spacing: 10,
    fontWeight: 'bold',
    labelColor: 'red',
    setApartmentId: () => {}
}

export default CustomPopover;