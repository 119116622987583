import React, { useEffect, useState } from "react";
import {useBuildingDetailsContext} from "../../context/BuildingDetailsContext";

const FloorPolygon = ({ classes, post }) => {
    const { areaRangeNr, bedroomNr, floorRangeNr, showAllSelected, onApartamentClick } = useBuildingDetailsContext();
    const [activeClass, setActiveClass] = useState(showAllSelected === false ? 'active' : '');

    const nrOfBedroomsFromPost = post?.acf?.bedrooms_number;
    const areaFromPost = post?.acf?.area;
    const floorFromPost = post?.acf?.building_floor;

    useEffect(() => {
        setActiveClass(showAllSelected === false ? 'active' : '');
    }, [areaRangeNr, bedroomNr, floorRangeNr, setActiveClass, showAllSelected]);

    useEffect(() => {
        if (bedroomNr.length) {
            if (bedroomNr?.find(({ number }) => number === +nrOfBedroomsFromPost)) {
                setActiveClass('active');
            }
            else {
                setActiveClass('');
            }
        }
    }, [areaRangeNr, bedroomNr, floorRangeNr, setActiveClass]);

    useEffect(() => {
        if (areaRangeNr.length) {
            if (+areaFromPost < areaRangeNr[0] || areaFromPost > areaRangeNr[1]) {
                setActiveClass('');
            }
        }
    }, [areaRangeNr, bedroomNr, floorRangeNr, setActiveClass]);

    useEffect(() => {
        if (+floorRangeNr.length) {
            if (+floorFromPost < floorRangeNr[0] || floorFromPost > floorRangeNr[1]) {
                setActiveClass('');
            }
        }
    }, [areaRangeNr, bedroomNr, floorRangeNr, setActiveClass]);

    return(
        <a href="3"
           onClick={onApartamentClick}
           key={post.id}
           className={`${classes.objectLinkWrapper} ${classes.objectLinkChild} ${activeClass}`}>
            <circle
                id={post.id}
                data-id={post.id}
                cx={post.acf.cx ? post.acf.cx : 0}
                cy={post.acf.cy ? post.acf.cy : 0}
                r="10"
                fill="#732425"
                className={classes.objectLocation}
            />
            <polygon
                data-id={post.id}
                points={post?.acf?.polygon}
                fill="#732425"
                className={`${post?.acf?.not_started_yet ? classes.notStartedHighlights 
                    : post?.acf?.is_solded ? classes.isSolded : classes.startedActually} 
                ${classes.objectPolygon} ${activeClass}` }
                data-area={post?.acf?.area}
                data-floor={post?.acf?.building_floor}
                data-object={post?.acf?.lamela_obj}
                title={post?.title.rendered}
            />
        </a>
    );
};

export default FloorPolygon;