import arrowPrev from './images/arrow-prev.svg';
import arrowNext from './images/arrow-next.svg';
import menuOpen from "../../components/header/images/menu.svg";
import arrowRotateLeft from './images/rotate-angle-left.svg'
import arrowRotateRight from './images/rotate-angle-right.svg'
import closeIcon from "../../components/Modal/images/close-large.svg";

export default () => ({
    buildingCatContainer: {
        height: '100vh',
        overflow: 'hidden',
        position: 'relative'
    },
    buildingCatIconNextPrev: {
        // position: 'absolute',
        // bottom: 0,
        // left: 0
    },
    nextPrevContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "56px",
        height: "56px",
        cursor: "pointer",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        transition: "all 0.4s ease 0s",
        overflow: "hidden",
        textAlign: "center",
        background: 'transparent',
        border: 'none',
        '&:hover': {
            backgroundColor: "rgb(3 3 3 / 20%)",
            color: "rgba(255,255,255,1)"
        }
    },
    prev: {
        // position: "fixed",
        // top: "calc(50vh - 22px)",
        // zIndex: 25,
        // WebkitTouchCallout: "none",
        // color: "rgba(255,255,255,0.8)",
        // fontSize: "22px",
        left: "30px",
        background: `url(${arrowRotateLeft})  no-repeat center / 25px rgba(0,0,0,0.5)`
    },
    next: {
        // position: "fixed",
        top: "c1alc(50vh - 22px)",
        zIndex: 25,
        WebkitTouchCallout: "none",
        color: "rgba(255,255,255,0.8)",
        fontSize: "22px",
        right: "30px",
        background: `url(${arrowRotateRight})  no-repeat center / 25px rgba(0,0,0,0.5)`
    },

    btnPrevOuter: {
        position: "fixed",
        bottom: "calc(50vh - 22px)",
        display: "flex",
        flexDirection: "column",
        alignContent: "space-around",
        justifyContent: "center",
        alignItems: "center",
        left: 0,
        width: 100
    },

    btnRightOuter: {
        position: "fixed",
        bottom: "calc(50vh - 22px)",
        display: "flex",
        flexDirection: "column",
        alignContent: "space-around",
        justifyContent: "center",
        alignItems: "center",
        right: 0,
        width: 100,
        zIndex: 999999
    },

    /* MODAL DETAILS*/
    modalDetailsWrapper: {
        padding: "28px 48px 10px 48px",
        display: "flex",
        flexWrap: "wrap",
        height: "calc(100vh - 290px)",
        overflowY: "auto",
        '@media(max-width: 992px)': {
            display: "block",
            height: 'auto'
        },
        '@media(max-width: 767px)': {
            padding: '28px 20px 10px 20px'
        }
    },
    modalDetailsInner: {
        padding: "0 25px 10px 0",
        marginBottom: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: 1,
        '@media(max-width: 767px)': {
            padding: 0
        }
    },

    lightBoxReactModal: {
        zIndex: '9999',
        '& .ril-inner': {
            '&::after': {
                content: '""',
                position: "absolute",
                width: "100%",
                height: "100%",
                background: "#040404b5",
                zIndex: -1
            }
        },
        '& img': {
            // maxHeight: 600,
            // position: "absolute",
            // left: "50%",
            // top: "50%",
            // WebkitTransform: "translate(-50%, -50%) !important",
            // transform: "translate(-50%, -50%) !important",
            // '@media(max-width: 992px)': {
            //     width: "90%",
            // }
        },
        '& .ril-close': {
            width: "56px",
            height: "56px",
            position: "fixed",
            right: "24px",
            top: "24px",
            background: `url(${closeIcon}) no-repeat 0 0 / contain transparent`,
            cursor: "pointer",
            opacity: 1,
            display: "block",
            transition: "all 0.5s ease 0s",
            border: 'none',
            '@media(max-width: 600px)': {
                backgroundColor: '#000',
                borderRadius: 50,
                zIndex: 999,
                top: "15px",
                width: "40px",
                backgroundRepeat: "no-repeat",
                height: "40px"
            }
        },
        '& .ril__toolbarSide': {
            listStyle: 'none'
        }
    },

    modalDetailsGallery: {
        overflow: 'hidden',
        flex: 1,
        position: 'relative',
        height: 600,
        '@media(max-width: 992px)': {
            height: 300
        },
        '& .slider-container': {
            maxWidth: '100vw',
            width: '100%',
            overflow: 'hidden'
        },
        '& .slider-custom': {
            //NEW
            '& .slick-slider': {
                '& .slick-list': {
                    display: 'flex'
                }
            },
            //END NEW
            '& img': {
                margin: 'auto',
                '@media(max-width: 992px)': {
                    objectFit: 'contain',
                    width: '100%'
                },
                '@media(max-width: 576px)': {
                    maxHeight: 200
                },
                '& .slick-list': {
                    position: 'relative',
                    width: '100%',
                    float: 'left'
                }
            }
        },
        '& .slick-track': {
            display: 'flex !Important'
        },
        '& .slick-slide': {
            height: 'auto'
        },
        '& .slick-arrow': {
            width: "56px",
            cursor: "pointer",
            height: "56px",
            display: "flex",
            overflow: "hidden",
            textAlign: "center",
            transition: "all 0.4s ease 0s",
            alignItems: "center",
            borderRadius: "50%",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
        },
        '& .slick-prev': {
            top: "calc(50% - 22px)",
            color: "rgba(255,255,255,0.8)",
            left: "10px",
            zIndex: 25,
            position: "absolute",
            fontSize: "22px",
            backgroundColor: "transparent",
            backgroundImage: "url(" + arrowPrev + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "contain",
            '@media(max-width: 576px)': {
                top: 0,
                width: "20px",
                height: '100%'
            },
        },
        '& .slick-next': {
            top: "calc(50% - 22px)",
            color: "rgba(255,255,255,0.8)",
            right: "10px",
            zIndex: 25,
            position: "absolute",
            fontSize: "22px",
            backgroundColor: "transparent",
            backgroundImage: "url(" + arrowNext + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "contain",
            '@media(max-width: 576px)': {
                top: 0,
                width: "20px",
                height: '100%'
            }
        }
    },
    parentImageGallery: {
        position: 'relative',
        height: 600,
        '@media (max-width: 992px)': {
            height: 300
        }
    },
    imageSlider: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        transition: "left .3s ease",
        backgroundColor: "#fff",
        cursor: 'pointer'
    },
    modalInnerContent: {
        '& h1': {
            color: "#14171c",
            fontSize: "24px",
            textTransform: "uppercase",
            margin: "0 0 20px"
        },
        '& h2': {
            color: "#7b7e86",
            fontSize: "16px",
            lineHeight: 1.2,
            margin: "0 0 20px"
        },
        '& h3': {
            fontSize: "16px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            color: "#d3b298"
        }
    },
    apartmentView: {
        margin: "15px 0",
        borderBottom: "1px solid rgba(41, 41, 41, 0.18)",
        display: "flex",
        justifyContent: "space-between",
        '@media(max-width: 767px)': {
            display: 'block'
        },

        '& div': {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            '& label': {
                fontWeight: 500,
                paddingRight: 10
            }
        }
    },
    orientationBadge: {
        paddingTop: 10,
        paddingBottom: 10,
        '& img': {
            margin: '0 auto',
            display: 'table',
            width: '100%'
        }
    },

    modalOuterButtons: {
        display: 'flex',
        '@media(max-width: 767px)': {
            display: 'block'
        }
    },

    askForPrice: {
        width: '100%',
        marginRight: 20,
        '@media(max-width: 767px)': {
            marginRight: 0,
        },

        '& a': {
            backgroundColor: '#568cbd !important',

            '&:hover': {
                backgroundColor: '#28759a !important',
            }
        }
    },
    downloadPDF: {
        width: '50%',
        '@media(max-width: 767px)': {
            width: '100%',
        },

        '& a': {
            color: "#fff",
            width: "100%",
            border: "0 solid #999",
            cursor: "pointer",
            margin: "0 0 4px",
            display: "inline-block",
            padding: "8px 0px 8px 0px",
            fontSize: "14px",
            textAlign: "center",
            transition: "all 0.4s ease 0s",
            textDecoration: "none",
            backgroundColor: "#cf9c56",
            '&:hover': {
                backgroundColor: '#976e36 !important'
            }
        }
    },
    modalInnerButton: {
        '& a': {
            color: "#fff",
            fontSize: "14px",
            backgroundColor: "#cf9c56",
            border: "0 solid #999",
            padding: "8px 0px 8px 0px",
            cursor: "pointer",
            transition: "all 0.4s ease 0s",
            margin: "0 0 4px",
            display: "inline-block",
            textDecoration: "none",
            width: "100%",
            textAlign: 'center'
        }

    },
    modalDisclaimer: {
        fontSize: "7px",
        padding: "10px 48px",
        lineHeight: 2,
        letterSpacing: "normal",
        color: "#7b7e86",
        float: 'left',
        height: 'auto',
        '@media(max-width: 767px)': {
            padding: 10
        }
    },

    roundObjectText: {
        width: 100,
        textAlign: 'center',
        textTransform: "uppercase",
        fontWeight: 500,
        color: "#ae8f5a",
        textShadow: '1px 1px #000000'
    }
});