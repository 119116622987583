import checkmarkImg from './images/checkmark.svg'
import closeIcon from "../Modal/images/close-large.svg";

export default () => ({
    contactContainer: {
        display: "flex",
        height: "100%",
        alignContent: "flex-start",
        '@media(max-width: 992px)': {
            display: "block"
        },
        '& h2': {
            fontSize: "24px",
            fontWeight: "normal",
            margin: "30px 0 0",
            textAlign: "left",
            color: "#fff",
            '@media(max-width: 992px)': {
                fontSize: "15px",
                textAlign: 'center',
                marginTop: '0px !important',
                padding: '10px 0'
            },
        }
    },
    contactLeft: {
        alignSelf: "center",
        padding: "0 50px",
        '@media(max-width: 992px)': {
            paddingBottom: 5,
            marginTop: '15px !important',
            height: 40,
            lineHeight: '40px'
        }
    },
    contactRight: {
        flex: 2,
        display: "flex",
        backgroundColor: "#35373b",
        flexFlow: "column",
        padding: "4% 8%",
        overflowY: "auto",
        height: '100%'
    },
    contactInner: {
        position: "relative",
        paddingBottom: "30px",
        '@media(max-width: 992px)': {
            paddingBottom: 10
        },
        '& h2': {
            padding: "0",
            fontSize: "24px",
            fontWeight: "normal",
            margin: "30px 0 0",
            textAlign: "left",
            color: "#fff",
            '@media(max-width: 992px)': {
                fontSize: "15px",
                marginTop: 0
            }
        },
        '& span': {
            fontSize: "14px",
            fontWeight: "normal",
            textAlign: "left",
            color: "#7b7e86",
            margin: "10px 0 35px"
        },
        '& label': {
            color: "#fff",
            fontSize: "12px",
            margin: "0 0 10px",
            display: "block"
        },
        '& input, textarea': {
            fontSize: '16px',
            padding: "15px",
            border: "0 none",
            borderBottom: "1px solid #aaa",
            width: "100%",
            maxWidth: "calc(100% - 30px)",
            textAlign: "left",
            backgroundColor: "#14171c22",
            color: "#fff",
            '@media(max-width: 992px)': {
                padding: '0 10px',
                height: 30
            }
        },
    },
    phoneInner: {
        display: "flex",
        width: '100%',
    },
    prefixInput: {
        flex: "0 0 40px",
        marginRight: "5px"
    },
    termsAndConditionsInner: {
        display: 'flex',
        alignItems: 'center',
        '& input:checked + span:before': {
            display: "block",
            content: '""',
            textAlign: "center",
            color: "#41B883",
            position: "absolute",
            background: `url(${checkmarkImg}) no-repeat 0 0`,
            left: "8px",
            top: "8px",
            fontSize: "14px",
            cursor: "pointer",
            width: 15,
            height: 15
        },
        '& label': {
            color: '#fff',
            display: "flex",
            alignItems: "center",
            margin: "0",
            fontSize: "12px",
            cursor: "pointer",
            position: 'relative'
        },
        '& input': {
            height: "24px",
            width: "24px",
            WebkitAppearance: "none",
            MozAppearance: "none",
            OAppearance: "none",
            appearance: "none",
            border: "1px solid #aaa",
            borderRadius: "4px",
            outline: "none",
            transitionDuration: "0.3s",
            backgroundColor: "transparent",
            cursor: "pointer",
            marginRight: "17px",
            '&:checked': {
                border: '1px solid #41B883'
            },
            '&.invalid': {
                border: '1px solid #c96262'
            },
            '& span': {
                '&::before': {
                    display: "block",
                    content: '',
                    textAlign: "center",
                    color: "#41B883",
                    position: "absolute",
                    background: `url(${checkmarkImg}) no-repeat 0 0 / contain transparent`,
                    left: "6px",
                    top: "4px",
                    fontSize: "14px",
                    cursor: "pointer"
                }
            }
        }
    },
    containerButton: {
        flexWrap: "wrap",
        margin: "30px 0 0px",
        '& button': {
            width: "100%",
            color: '#fff',
            fontSize: "18px",
            padding: "20px 0",
            backgroundColor: "#cf9c56",
            border: "0 solid #999",
            cursor: "pointer",
            transition: "all 0.4s ease 0s",
            margin: "0 0 4px",
            display: "inline-block",
            textDecoration: "none",
            '@media(max-width: 992px)': {
                padding: "10px 0",
            },
            '&:hover': {
                backgroundColor: "#bd8435",
            }
        }
    },
    bestTime: {
        margin: "0 0 20px",
        fontSize: "14px",
        color: "#6a6b70",
        '& h3': {
            '@media(max-width: 992px)': {
                fontSize: 12
            }
        }
    },
    datePicker: {
        paddingBottom: 30,
        '& input': {
            padding: "15px",
            border: "0 none",
            borderBottom: "1px solid #aaa",
            width: "100%",
            maxWidth: "calc(100% - 30px)",
            textAlign: "left",
            backgroundColor: "#14171c22",
            fontSize: "16px",
            color: "#fff",
            '@media(max-width: 992px)': {
                height: 30,
                padding: '0 10px'
            }
        },
        '& label': {
            color: "#fff",
            fontSize: "12px",
            margin: "0 0 10px",
            display: "block"
        }
    },

    successMessage: {
        color: 'green'
    },

    errorMessage: {
        color: 'red'
    }
});