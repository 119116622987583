import closeIcon from './images/close-large.svg'

export default () => ({
    '.landscape': {

    },
    modalBox: {
        top: "0",
        left: "0",
        width: "100%",
        height: "100vh",
        position: "fixed",
        background: "#00000050",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        transition: "all 0.4s ease 0s",
        overflow: "hidden",
        zIndex: 999,
        WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",
        '&.active': {
            height: "100vh",
        }
    },
    box: {
        position: "relative",
        width: "90%",
        margin: "0 auto",
        height: "auto",
        maxHeight: "85vh",
        marginTop: "calc(97vh - 85vh - 20px)",
        background: "#fff",
        borderRadius: "4px",
        padding: "0px",
        border: "1px solid #999",
        overflow: "auto",
        '&.customBox': {
            backgroundColor: "#c89e68bb"
        },
        '@media(max-width: 600px)': {
            marginTop: "0",
            width: "100%",
            height: "100vh",
            maxHeight: "100vh"
        }
    },
    closeIcon: {
        width: "56px",
        height: "56px",
        position: "fixed",
        right: "24px",
        top: "24px",
        background: `url(${closeIcon}) no-repeat 0 0 / contain transparent`,
        cursor: "pointer",
        opacity: 1,
        display: "block",
        transition: "all 0.5s ease 0s",
        '@media(max-width: 600px)': {
            backgroundColor: '#000',
            borderRadius: 50,
            zIndex: 999,
            top: "10px",
            width: "40px",
            backgroundRepeat: "no-repeat",
            height: "40px"
        }
    },

    landscape: {
        backgroundColor: '#000',
        borderRadius: '50px'
    }
})