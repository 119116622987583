export default () => ({
    cardOuter: {
        position: 'absolute',
        right: 15,
        top: 70,
        padding: 15,
        background: 'rgb(255 255 255 / 90%)',
        zIndex: 9999,
        maxWidth: 300,
        opacity: ({ isOpen }) => !isOpen ? "0" : "1",
        transition: "all .2s",
        visibility: ({ isOpen }) => {
            console.log('ISOPEN', isOpen);
            return !isOpen ? "hidden" : "visible";
        },
    }
})