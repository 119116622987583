import menuOpen from './images/menu.svg';
import menuClose from './images/menu_close.svg';
import arrowLeft from './images/arrow-left.svg';
import likeIcon from './images/like.svg';

export default () => ({
        fullWidth: {
            width: '100%'
        },
        header: {
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            background: 'linear-gradient(to bottom, rgb(28 28 28 / 71%) 0%,rgb(0 0 0 / 31%) 50%,rgb(255 255 255 / 0%) 100%)',
            paddingTop: 10,
            position: 'fixed',
            zIndex: 88,
            width: '100%',
            borderBottom: '1px solid rgb(255 255 255 / 50%)',

            '@media(max-width: 992px)': {
                position: 'absolute'
            }
        },
        menus: {

        },
        hamburgerIcon: {
            position: "fixed",
            top: "10px",
            left: "22px",
            zIndex: 50,
            alignItems: "center",
            backgroundColor: "transparent",
            width: "auto",
            color: "#fff",
            fontSize: "16px",
            textTransform: "uppercase",
            borderRadius: "0",
            display: "flex",
            justifyContent: "center",
            height: "56px",
            cursor: "pointer",
            transition: "all 0.4s ease 0s",
            overflow: "hidden",
            textAlign: "center",
            '& button': {
                height: 24,
                width: 24
            }
        },
        hamburgerButton: {
            outline: "none",
            border: "none",
            cursor: "pointer",
            position: "relative",
            zIndex: 10,
            backgroundColor: "transparent",
            backgroundImage: "url(" + menuOpen + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "contain",
            WebkitTapHighlightColor: "transparent",
            transition: "all 0.4s ease 0s",
            '&.open': {
                background: "url(" + menuClose + ") no-repeat center transparent",
            }
        },
        leftArrow: {
            background: `url(${arrowLeft}) no-repeat center transparent`,
            backgroundSize: "cover",
            color: "rgba(255, 255, 255, 0.8)",
            width: "24px",
            height: "24px",
            transition: "all 0.4s ease 0s",
            flex: "0 0 24px",
            display: "inline-block",
            marginRight: "30px"
        },
        menuTitle: {
            whiteSpace: "nowrap",
            color: "#fff",
            fontSize: "16px",
            textTransform: "uppercase",
            marginLeft: "16px"
        },
        pagesMenuTitle: {
            marginLeft: 0,
            '& a': {
                display: 'flex'
            }
        },
        menuPanel: {
            // maxWidth: "370px",
            backgroundSize: "117px 188px",
            // transition: "all 0.4s cubic-bezier(0.42, 0, 0.34, 1.01)",
            // position: "fixed",
            position: "absolute",
            left: "-100%",
            top: "0",
            height: "100vh",
            width: "100%",
            visibility: "hidden",
            opacity: 0,
            padding: "3em",
            "&.open": {
                visibility: "visible",
                width: "100%",
                left: "0",
                opacity: 1,
                '@media(max-width: 992px)': {
                    paddingLeft: 0
                }
            },
            zIndex: 45,
            overflow: "hidden",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            transition: "all 0.4s ease 0s",
            WebkitBackdropFilter: "blur(4px)",
            backdropFilter: "blur(4px)",
            '@media(min-width: 776px)': {
                maxWidth: 500
            }
        },
        buildings: {
            float: 'left',
            width: 'auto',
            height: 'calc(100% - 75px)',
            padding: '10px 10px',
            margin: '50px 0 0',
            overflowY: 'auto',

            // '@media(max-width: 992px)': {
            //     maxWidth: 270
            // }
        },
        sidebarItemNav: {
            border: "solid 1px #fff",
            fontSize: "18px",
            textAlign: "center",
            margin: 0,
            marginBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
            color: "#fff",
            opacity: 0.8,
            '& label': {
                textAlign: "center",
                cursor: "pointer",
                display: "block",
                padding: "13px 20px"
            },
            '& .description': {
                maxHeight: "0",
                transition: "all 0.6s ease 0s",
                overflow: "hidden"
            }
        },
        exploreLink: {
            color: "#fff",
            border: "0 solid #999",
            cursor: "pointer",
            margin: "10px 0px 4px",
            fontSize: "14px",
            transition: "all 0.4s ease 0s",
            textDecoration: "none",
            backgroundColor: "#cf9c56",
            width: "100%",
            display: "block",
            padding: "10px 0",
            float: 'left'
        },
        desc: {
            padding: "0px 20px",
            marginBottom: 20,
            width: 'auto',
            display: 'block',
            float: 'left'
        },
        itemActive: {
            opacity: 1,
            '& .description': {
                maxHeight: "3260px"
            }
        },
        buttonLink: {
            fontSize: "14px",
            backgroundColor: "#cf9c56",
            border: "0 solid #999",
            cursor: "pointer",
            transition: "all 0.4s ease 0s",
            margin: "10px 15px",
            textDecoration: "none",
            display: "block",
            padding: "7px",
            color: "#fff",
            '&:hover': {
                backgroundColor: "rgba(255,255,255,0.2)"
            }
        },
        contactUs: {
            color: "#fff !important",
            padding: "29px 30px 20px 0",
            margin: "0",
            position: "fixed",
            zIndex: 20,
            top: "0",
            right: "10px",
            width: "145px",
            textAlign: "right",
            fontSize: "16px",
            fontWeight: 400,
            textDecoration: "none",
            background: `url(${likeIcon}) no-repeat right 22px transparent`,
            textTransform: "uppercase",
            lineHeight: "15px",
            '& span': {
                '@media(max-width: 992px)': {
                    display: 'none'
                }
            }
        },
        propertiesHeaderButton: {
            '& span': {
                '@media(max-width: 992px)': {
                    display: 'none'
                }
            }
        },
        objectNameInner: {
            textAlign: 'center',
            display: "table",
            margin: "0 auto",
            float: "none",
            textTransform: 'uppercase'
        },
        objectName: {
            color: "#fff",
            zIndex: 20,
            textAlign: "center",
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: 400,
            textDecoration: "none",
            position: "relative",
            paddingTop: 15,
            float: 'left',
            width: '100%',
            '@media(max-width: 992px)': {
                fontSize: 16,
                paddingTop: 10
            }
        }
    }
)