export default () => ({
        interiorPath: {
            opacity: '0.5',
            transition: 'all 0.4s ease 0s',
            '&:hover': {
                opacity: 0.8,
                fill: '#6BC33E'
            }
        },
        overlay: {
            WebkitFilter: "blur(10px)",
            MozFilter: "blur(10px)",
            OFilter: "blur(10px)",
            msFilter: "blur(10px)",
            filter: "blur(10px)",
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: -2
        },
        disableTransform: {
            transform: 'inherit !important'
        },
        customWrapperClass: {
          overflow: 'scroll'
        },
        viewsPanel: {
            position: "relative",
            height: "100vh",
            width: "100vw",
            overflow: "scroll"
        },
        mainContainer: {
            overflow: 'hidden',
            height: '100vh'
        },
        buildingContainer: {
            transition: "opacity 1s ease-out",
            opacity: 0,
            height: 0,
            '&.fade-in': {
                opacity: 1,
                height: "100vh"
            },
        },
        containerDiv: {
            overflow: 'hidden',
            // height: '100vh',
            '& svg': {
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: 10,
                userSelect: "none",
                transformOrigin: "0px 0px"
            }
        },
        buildingOuter: {
            opacity: 0,
            visibility: 'hidden',
            transition: "all 0.4s ease 0s",
            '&.active': {
                opacity: 1,
                visibility: 'visible',
            }
        },
        complexWrapper: {
            position: "relative",
            minWidth: "100vw",
            minHeight: "100vh",
            height: "100vh",
            transition: "all 0.5s ease 0s",
            transformOrigin: "0 0"
        },
        viewWrapper: {
            position: "absolute",
            minHeight: "100%",
            height: "100%",
            width: "auto",
            display: "block",
            transformOrigin: "0px 0px",
            transition: "all 1.5s ease 0.5s",
            userSelect: "none",
            zIndex: 10,
            lineHeight: 0,
            '&.active': {
                minHeight: "100%",
                height: "100%",
                width: "auto",
                opacity: 1,
                left: "0",
                justifyItems: "center",
                zIndex: 12,
            },
            '& svg': {
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: 10,
                userSelect: "none",
                transformOrigin: "0px 0px",
                '& polygon': {
                    fill: "rgba(115, 36, 36, 0.38)",
                    strokeWidth: 0.5,
                    opacity: 1
                }
            }
        },
        complexImg: {
            opacity: 1,
            width: '100%',
            height: 'auto'
        },
        footerImages: {

        },
        footerOuter: {
            display: "flex",
            justifyContent: "center",
            transform: "translateX(-50%)",
            width: "667px",
            height: "72px",
            WebkitBackdropFilter: "blur(4px)",
            backdropFilter: "blur(4px)",
            backgroundColor: "rgba(0,0,0,0.74)",
            alignItems: "center",
            transition: "all 0.3s ease 0s",
            position: "fixed",
            left: "50vw",
            bottom: "25px",
            zIndex: 25,
            '@media(max-width: 600px)': {
                width: '90%'
            }
        },
        footerInner: {
            paddingLeft: 10,
            paddingRight: 10,
            zIndex: 999,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& button': {
                '& svg': {
                    fill: '#fff'
                }
            }
        },
        horizontalSlider: {
            width: "100%",
            maxWidth: "500px",
            height: 5,
            margin: "auto",
            outline: "none",
            transition: "background 450ms ease-in",
            WebkitAppearance: "none",
        },
        buttonInner: {
            width: 30,
            height: 30,
            cursor: "pointer",
            backgroundColor: "transparent",
            border: 'none',
            position: 'relative',
            left: 0
        },
        buttonSvg: {
          position: 'relative',
            width: 30
        },
        reactSliderInner: {
            width: '70%',
            '& .css-yvszuv-Slider': {
                width: "100%",
                maxWidth: "500px",
                height: 1,
                margin: "auto",
                '& .css-3g5hux-Slider': {
                    backgroundColor: '#d3b298'
                },
                '& .css-1ldz52l-Slider': {
                    backgroundColor: '#d3b298'
                }
            }
        },
        objectLinkWrapper: {
            '& circle': {
                r: "20px",
                strokeWidth: "0px",
                vectorEffect: "non-scaling-stroke",
                stroke: "#fff",
                fill: "#fff",
                opacity: 1,
                transition: "all 0.5s ease 0s"
            },
            '&:hover': {
                '& circle': {
                    strokeOpacity: 0,
                    strokeWidth: 90,
                    r: 1,
                    stroke: '#a00'
                }
            },
            '&.active': {
                '& polygon': {
                    opacity: 1
                },
                '& circle': {
                    strokeOpacity: 0,
                    strokeWidth: 90,
                    r: 1,
                    stroke: '#a00'
                }
            }
        },
        objectLinkChild: {
            '& circle': {
                r: "0.2",
                strokeWidth: 0,
                fill: "transparent"
            },
            '&:hover': {
                '& circle': {
                    r: "0.2",
                    strokeWidth: 0,
                    fill: "transparent"
                }
            },
            '&.active': {
                '& polygon': {
                    // opacity: '0 !important'
                },
                '& circle': {
                    strokeOpacity: 0,
                    // strokeWidth: 0,
                    strokeWidth: 10,
                    r: 1,
                    stroke: '#a00'
                }
            }
        },
        mainPageObjects: {
            '& polygon': {
                fill: "rgba(115, 36, 36, 0.38)",
                strokeWidth: 0.5,
                opacity: 0
            },
            '&:hover': {
                '& polygon': {
                    opacity: 1,
                    fill: "rgba(115, 36, 36, 0.38)",
                    strokeWidth: 0.5,
                    stroke: "#fff"
                }
            }
        },
        objectPolygon: {
            transformOrigin: "0px 0px",
            stroke: "#fff",
            vectorEffect: "non-scaling-stroke",
            cursor: "default",
            transition: "all 0.4s ease 0s",
            outline: "none",
            opacity: 0,
            WebkitTouchCallout: "none",
            WebkitUserSelect: "none",
            KhtmlUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
            WebkitTapHighlightColor: "transparent",
            strokeWidth: 1,
            '&.active': {
                cursor: 'pointer',
                opacity: 1
            }
        },
        startedActually: {
            // fill: "rgba(122, 255, 122, 0.3)",
            fill: 'rgb(22 163 74 / 55%)',

            '&:hover': {
                // opacity: 1,
                stroke: "#1ace1a",
                // fill: "rgba(0, 175, 0, 0.65)"
                fill: 'rgb(22 101 52 / 65%)'
            },
        },
        notStartedHighlights: {
            fill: "rgba(255, 235, 59, 0.3)",
            '&:hover': {
                // opacity: 1,
                stroke: "#1ace1a",
                fill: "rgba(255, 235, 59, 0.6)"
            },
        },

        isSolded: {
            fill: "rgba(224,36,36, 0.3)",
            '&:hover': {
                // opacity: 1,
                stroke: "#1ace1a",
                fill: "rgba(224,36,36, 0.6)"
            },
        },
        polygonDetails: {

        },

        filterPrevBtn: {
            width: 50,
            height: 30,
            float: 'left',
            zIndex: 999999,
            left: 0
        },

        filterNextBtn: {
            width: 50,
            height: 30,
            float: 'right',
            zIndex: 999999,
            right: 0
        },

        scrollLRDetails: {
            bottom: "50px !important",
        },

        scrollLeftToRightSection: {
            width: '100%',
            display: 'none',
            bottom: "0",
            left: "0",
            zIndex: 999999,
            position: "fixed",
            fontSize: "12px",
            borderRadius: "0",

            '&.landscape': {
                display: 'none'
            },

            '@media(max-width: 767px)': {
                display: 'block'
            },

            '& .slider-thumb': {
                border: "1px solid #d3b298",
                background: "#d3b298",
                fontWeight: "normal",
                color: "#d3b298",
                borderRadius: "50%",
                width: "22px",
                height: "22px",
                top: "-11px",
                userSelect: "none",
                outline: "none"
            },
            '& .slider-track': {
                left: "0px",
                right: "0px",
                height: "2px",
                background: "#d3b298",
                '&.slider-track-0': {
                    background: "rgba(216, 216, 216, 0.3)"
                },
                '&.slider-track-2': {
                    background: "rgba(216, 216, 216, 0.3)"
                }
            },
        },

        primaryParentLR: {
            bottom: '0px !important'
        },

        scrollLRInner: {
            width: '100%',
            // paddingTop: 30,
            marginTop: 40,
            paddingBottom: 30,
            // height: 30,
            background: "rgb(51 51 51 / 79%)",
            backdropFilter: "blur(6px)",
        },

        sliderSection: {
            float: 'left',
            width: '85%',
            paddingLeft: 25,
            paddingRight: 25
        },

        zoomButtons: {
            float: 'left',
            width: '90%',
            height: 'auto',
            paddingLeft: 20,
            paddingRight: 20
        },

        zOutBtn: {
            position: 'relative',
            float: 'left',
            width: 30,
            height: 30,
            margin: 0,
            padding: 0,
            outline: 'none',
            border: 'none',
            background: "#c89e68",
            borderRadius: "50px",

            '& svg': {
                width: 30,
                height: 30,
            }
        },

        zInBtn: {
            position: 'relative',
            float: 'right',
            width: 30,
            height: 30,
            margin: 0,
            padding: 0,
            outline: 'none',
            border: 'none',
            background: "#c89e68",
            borderRadius: "50px",

            '& svg': {
                width: 30,
                height: 30,
            }
        },

        imgLandscape: {
            '@media(max-width: 992px)': {
                height: '600px !important'
            }
        },

        primaryPageImg: {
            '@media(max-width: 992px)': {
                height: '1000px !important'
            }
        },

        navigateSideText: {
            margin: "0",
            padding: "10px 20px",
            marginBottom: "10px",
            fontSize: "16px",
            color: "#fff",
            fontWeight: 300
        }
    }
)