import React, {useState, useCallback} from "react";
import $ from 'jquery';
import {createUseStyles} from 'react-jss';
import Header from "../../components/header/Header";
import Buildings from "../../components/buildings/Buildings";
import homeStyles from "./home.styles";
import useComponentVisible from "../../hooks/useComponentVisible";
import {useBuildingsCat} from "../../context/BuildingCategoriesContext";
import Popover from "../../components/Popover/Popover";
import getCoordinates from "../../utils/getCoordinates";
import Loader from "../../components/Loader/Loader";

const useStyles = createUseStyles(homeStyles);

const HomePage = () => {
    const classes = useStyles();
    const [showPopup, setShowPopup] = useState({});
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);
    const [bottomClass, setBottomClass] = useState('');
    const { buildingsCat, loader } = useBuildingsCat();
    const parentCat = buildingsCat.filter(({ parent }) => parent === 0);
    const [elementId, setElementId] = useState('');

    const selectedObject = elementId && buildingsCat.filter(({ id }) => id === +elementId);

    const onBuildingClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        getCoordinates(e, setElementId, setShowPopup, setBottomClass, setIsComponentVisible);
    }


    return(
        <>
            {loader ? (
                <Loader />
            ) : (
                <div id="buildings-info" className={`${classes.main} view-building-info`}>
                    <Header
                        onBuildingClick={onBuildingClick}
                        showHamburgerMenu
                    />
                    <Buildings
                        onBuildingClick={onBuildingClick}
                        parentCat={parentCat}
                        isComponentVisible={isComponentVisible}
                        elementId={elementId}
                    />
                    {selectedObject && (
                        <div ref={ref}>
                            <Popover
                                bottomClass={bottomClass}
                                showPopup={showPopup}
                                isComponentVisible={isComponentVisible}
                                selectedObject={selectedObject}
                                buildingDisplay
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default HomePage;