import React, { useState, useCallback } from "react";
import {createUseStyles} from 'react-jss';
import headerStyles from "./header.styles";
import { Link } from 'react-router-dom';
import {useHeader} from "../../context/HeaderContext";


const useStyles = createUseStyles(headerStyles);

const Menus = ({ parentCat, onBuildingClick, showHamburgerMenu }) => {
    const classes = useStyles();
    const { setActiveId } = useHeader();
    const [isOpen, setOpen] = useState(false);
    const [expanded, setIsExpanded] = useState('');

    const onLabelClick = (e, name, id) => {
        if (expanded === name) {
            setIsExpanded('');
        } else {
            setIsExpanded(name);
            setActiveId(id);
        }
        onBuildingClick(e);
    }

    return(
        <div>
            {showHamburgerMenu ? (
                <div className={classes.hamburgerIcon}>
                    <button
                        onClick={() => setOpen(!isOpen)}
                        className={`${classes.hamburgerButton} ${isOpen ? "open" : "close"}`}
                    />
                    <div className={classes.menuTitle}>
                        <a
                            onClick={() => setOpen(!isOpen)}
                            className={`${classes.propertiesHeaderButton} ${isOpen ? "open" : "close"}`}
                        >
                            <span>Lista e Objekteve</span>
                        </a>
                    </div>
                </div>
            ) : (
                <div className={classes.hamburgerIcon}>
                    <div className={`${classes.menuTitle} ${classes.pagesMenuTitle}`}>
                        <Link to='/' className={classes.propertiesHeaderButton}>
                            <div className={classes.leftArrow} />
                            <span>Lista e Objekteve</span>
                        </Link>
                    </div>
                </div>
            )}
            <div className={`${classes.menuPanel} menu-outer ${isOpen ? "open" : "close"}`}>
                <div className={classes.buildings}>
                    {parentCat.map((item) => (
                        <div
                            className={`${classes.sidebarItemNav} building_item ${expanded === item.name ? classes.itemActive : ''}`}
                            key={item.id}
                            data-tardet={item.id}
                        >
                            <label htmlFor="" id={item.id} onClick={(e) => onLabelClick(e, item.name, item.id)} data-id={item.id}>{item.name}</label>
                            <div className='description'>
                                <div className={classes.desc}>
                                    <span style={{
                                        float: 'left',
                                        width: '100%'
                                    }}>{item.description}</span>
                                    {
                                       !item?.acf?.not_started_yet ? (
                                            <Link
                                                to={`/buildings/${item.id}`}
                                                className={classes.exploreLink}
                                            >
                                                Eksploro
                                            </Link>
                                        ) : (
                                           <button className={classes.exploreLink} disabled>
                                               Së shpejti
                                           </button>

                                       )
                                    }
                                </div>
                                {/*<a href="" onClick={() => onHandleClickHere(item.id)} className={classes.buttonLink}>Explore</a>*/}

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default Menus;