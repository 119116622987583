import React, {useEffect, useState, createRef, useCallback, useRef } from "react";
import axios from "axios";
import {createUseStyles} from 'react-jss';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import buildingsStyle from './buildings.styles';
import testImage from './images/image-primary.jpg';
import ReactSlider from "react-slider";
import './sliderStyle.css';
import useScreenSize from "../../hooks/useScreenSize";

const useStyles = createUseStyles(buildingsStyle);

const Buildings = ({ onBuildingClick, parentCat, isComponentVisible, elementId }) => {
    const classes = useStyles();
    const [width, setWidth]   = useState(window.innerWidth);
    const { orientation } = useScreenSize();
    const [transitionValue, setTransitionValue] = useState(0);

    const updateDimensions = () => {
        setWidth(window.innerWidth);

    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const [data, setData] = useState([]);

    const transformComponentRef = useRef(null);
    const [scale, setScale] = useState(width > 1600 ? 0.65 : 0.52);

    useEffect(() => {
        axios.get('https://www.monopolcity.com/wp-json/wp/v2/buildings').then((res) => {
            setData(res.data);
        }).catch();
    }, []);

    const onSliderChange = useCallback((event) => {
        if (transitionValue < event) {
            setTransitionValue(transitionValue + 60);
        }

        if (transformComponentRef.current) {

            if (event >= -1100) {
                transformComponentRef.current.setTransform(
                    //calculate x and y
                    -event,
                    transformComponentRef.current.state.positionY,
                );
            } else {
                transformComponentRef.current.setTransform(
                    //calculate x and y
                    event,
                    transformComponentRef.current.state.positionY,
                );
            }
        }
    }, []);

    return (
        <div id="main-container" className={`${classes.containerDiv} ${classes.mainContainer} building-view`} style={{
            maxWidth:'100%',
            maxHeight:'100%'
        }}>
            <TransformWrapper
                ref={transformComponentRef}
                initialScale={scale}
                minScale={1}
                maxScale={2.5}
                doubleClick={{
                    disabled: true
                }}
                wheel={{
                    wheelDisabled: true
                }}
            >
                {({ zoomIn, zoomOut, resetTransform, onZoomStart, ...rest }) => (
                    <React.Fragment>
                        <TransformComponent
                            wrapperStyle={{ maxWidth: "100%", maxHeight: "100vh" }}
                        >
                            <div>
                                <img
                                    className={`complex_img show ${orientation === 'landscape-primary' ? classes.primaryPageImg : 'portrait'}`}
                                     id="image-id"
                                     src={testImage}
                                     alt="#"
                                />
                                <svg
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 3000 1687"
                                    className={`${orientation === 'landscape-primary' ? classes.primaryPageImg : 'portrait'}`}
                                    // style={customImageStyle}
                                >
                                    {
                                        parentCat.map(parent => (
                                            <a
                                                href="#" onClick={(e) => onBuildingClick(e, parent.id)}
                                                className={
                                                    `building_link ${+elementId === parent.id& isComponentVisible ? 'active' : ''} 
                                                    ${classes.objectLinkWrapper} ${classes.mainPageObjects}`}
                                                    >

                                                        <circle
                                                            id={parent.id}
                                                            data-id={parent.id}
                                                            cx={parent.acf.cx}
                                                            cy={parent.acf.cy}
                                                            r="50"
                                                            fill="#732425"
                                                            className={classes.objectLocation}
                                                        />
                                                        <polygon
                                                            data-id={parent.id}
                                                            points={parent?.acf.polygon_code}
                                                            fill="#732425"
                                                            className={`${parent.id ? 'active' : ''}`}
                                                        />

                                                    </a>
                                                ))
                                            }
                                        </svg>
                                    </div>

                        </TransformComponent>
                    </React.Fragment>
                )}
            </TransformWrapper>
            <div className={`${classes.scrollLeftToRightSection} ${classes.scrollLRDetails} ${classes.primaryParentLR} ${orientation === 'landscape-primary' ? 'landscape' : 'portrait'}`}>
                <div className={classes.scrollLRInner}>
                    <h2 className={classes.navigateSideText}>Navigo anash</h2>
                    <div className={classes.sliderSection}>
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="slider-thumb"
                            trackClassName="slider-track"
                            defaultValue={0}
                            min={0}
                            max={1100}
                            minDistance={60}
                            onChange={onSliderChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Buildings;