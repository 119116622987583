import React from "react";
import './loader.css';

const Loader = () => {

    return(
        <div className="main-loader">
            <div className="loader"></div>
        </div>
    );
};

export default Loader;