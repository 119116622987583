import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from 'react-jss';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import * as moment from "moment";
import axios from "axios";
import {useHeader} from "../../context/HeaderContext";
import contactStyles from './contact.styles';

const useStyles = createUseStyles(contactStyles);

const Contact = ({ selectedObject }) => {
    const classes = useStyles();
    const { activeObjectName } = useHeader();
    const [contactData, setContactData] = useState({
        ['your-name']: '',
        ['your-lastname']: '',
        ['your-email']: '',
        ['phone-nr']: '+383',
        description: '',
        termsAndCondition: false
    });
    const [startDate, setStartDate] = useState(null);
    const [serverErrors, setServerErrors] = useState('');

    const onContactChange = useCallback((e) => {
        setContactData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }, [setContactData]);

    const onTermsAndConditionHandler = useCallback((e) => {
        setContactData((prevState) => ({
            ...prevState,
            termsAndCondition: e.target.checked
        }));
    }, []);

    const onDatePickerChange = useCallback((date) => {
        setStartDate(date);
    }, []);

    const onContactSubmit = useCallback((e) => {
        e.preventDefault();
        const termsAndConditions = contactData.termsAndCondition ? 'on' : 'off';
        const bodyFormData = new FormData();
        bodyFormData.append('your-name', contactData['your-name']);
        bodyFormData.append('your-lastname', contactData['your-lastname']);
        bodyFormData.append('your-email', contactData['your-email']);
        bodyFormData.append('phone-nr', contactData['phone-nr']);
        bodyFormData.append('phone-number', contactData['phone-number']);
        bodyFormData.append('object-name', activeObjectName);
        bodyFormData.append('your-message', contactData.description);
        bodyFormData.append('accept_terms_cond', termsAndConditions);
        bodyFormData.append('date-time', startDate);

        axios({
            method: "post",
            url: "https://www.monopolcity.com/wp-json/contact-form-7/v1/contact-forms/133/feedback",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
            })
            .catch(function (error) {
                setServerErrors(error.response);
            });
    }, [contactData]);

    const errorsStatusCodes = serverErrors.status === 400 || serverErrors.status === 402
        || serverErrors.status === 403 || serverErrors.status === 404 || serverErrors.status === 407
        || serverErrors.status === 406 || serverErrors.status === 407 || serverErrors.status === 408
        || serverErrors.status === 409 || serverErrors.status === 410

    return(
        <div className={classes.contactContainer}>
            <h2 className={classes.contactLeft}>{activeObjectName}</h2>
            <div className={classes.contactRight}>
                <form action="" onSubmit={onContactSubmit}>
                    <div className={classes.contactInner}>
                        <h2>Pyet për çmimin</h2>
                        <span>lini një takim ose informohuni</span>
                    </div>
                    <div className={classes.contactInner}>
                        <label htmlFor="">Emri</label>
                        <input
                            type="text"
                            name="your-name"
                            required={true}
                            onChange={onContactChange}
                        />
                    </div>
                    <div className={classes.contactInner}>
                        <label htmlFor="">Mbiemri</label>
                        <input
                            type="text"
                            name="your-lastname"
                            required={true}
                            onChange={onContactChange}
                        />
                    </div>
                    <div className={classes.contactInner}>
                        <label htmlFor="">Email</label>
                        <input
                            type="email"
                            required={true}
                            name="your-email"
                            onChange={onContactChange}
                        />
                    </div>
                    <div className={classes.contactInner}>
                        <label htmlFor="">Nr. tel</label>
                        <div className={classes.phoneInner}>
                            <input
                                type="tel"
                                name="phone-nr"
                                id="phone-nr"
                                required={true}
                                placeholder="+XXX"
                                className={classes.prefixInput}
                                maxLength="4"
                                value={contactData['phone-nr']}
                                onChange={onContactChange}
                            />
                            <input
                                type="tel"
                                name="phone-number"
                                required={true}
                                id="phone-number"
                                placeholder="XXXXXXXX"
                                className="popup_input"
                                maxLength="12"
                                onChange={onContactChange}
                            />
                        </div>
                    </div>
                    <div className={classes.bestTime}>
                        <h3>
                            Kur është koha më e mirë për t'ju kontaktuar për konfirmim?
                        </h3>
                    </div>
                    <div className={classes.datePicker}>
                        <label htmlFor="">Koha (opcionale)</label>
                        <DatePicker
                            showTimeSelect
                            selected={startDate}
                            dateFormat='dd/MM/yyyy EE hh:mm a'
                            onChange={(date) => onDatePickerChange(date)}
                            placeholderText="Start typing or select from the dropdown"
                        />
                    </div>
                    <div className={classes.contactInner}>
                        <label htmlFor="">Shënim (opcionale)</label>
                        <textarea
                            id=""
                            cols="30"
                            rows="3"
                            name="description"
                            onChange={onContactChange}
                        >
                        </textarea>
                    </div>
                    <div className={classes.termsAndConditionsInner}>
                        <label htmlFor="agree" className="terms_label">
                            <span className="cb"></span>
                            <input
                                type="checkbox"
                                name="accept_terms_cond"
                                id="accept_terms_cond"
                                className="popup_checkbox"
                                required={true}
                                onChange={onTermsAndConditionHandler}
                                value={contactData.termsAndCondition}
                            />
                                <span>Jam dakord me kushtet e
                                    <a
                                        className="popup_link"
                                       href="#"
                                       target="_blank"> përpunimit të të dhënave personale
                                    </a>
                                </span>
                        </label>
                    </div>
                    <div className={`${classes.contactInner} ${classes.containerButton}`}>
                        <button>Dërgo</button>
                    </div>
                    <div>
                        {serverErrors.status === 200 && (
                            <span className={classes.successMessage}>Mesazhi juaj është dërguar me sukses.</span>
                        )}
                    </div>
                    <div>
                        {
                            errorsStatusCodes && (
                                <span className={classes.errorMessage}>Diçka shkoi gabim. Ju lutem provoni përsëri.</span>
                            )
                        }
                    </div>
                </form>
            </div>
        </div>
    )
};

Contact.defaultProps = {
    selectedObject: []
}

export default Contact;