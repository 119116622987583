import $ from 'jquery';

export default function getCoordinates(e, setElementId, setShowPopup, setBottomClass, setIsComponentVisible) {
    const $polyg = $(e.target);
    const id = $polyg.attr("data-id");
    setElementId(id);
    const primaryContainer = $('#buildings-info a');
    const $circ = primaryContainer.find("[data-id='" + id + "']").eq(0);
    const c = $circ.position();

    let scroll  = document.documentElement.scrollTop || document.body.scrollTop;

    if (c.top === 0 && c.left === 0) {
        c.left = $circ.get(0).getBoundingClientRect().x;
        c.top = $circ.get(0).getBoundingClientRect().y;
        if (!(c.top && c.left)) {
            c.left = e.clientX;
            c.top = e.clientY;
        }
    }

    setShowPopup({
        left: c.left + scroll,
        top: c.top + scroll,
    });

    if (c.top < $(window).height() / 2) {
        setBottomClass('bottom');
    } else {
        setBottomClass('');
    }
    setIsComponentVisible(true);
}