export const parseQueryString = (queryString) => {
    if (!queryString) {
        return {};
    }

    const response = {};

    queryString.substring(1).split('&').forEach((part) => {
        const [key, value] = part.split('=');
        response[key] = decodeURIComponent(value);
    });

    return response;
}