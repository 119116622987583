import React, { useState, useEffect } from 'react';
import {createUseStyles} from 'react-jss';
import cardStyles from './card.styles';
import {useBuildingDetailsContext} from "../../context/BuildingDetailsContext";

const useStyles = createUseStyles(cardStyles);

function formatArray(array){
    const arr = array.map(({ number }) => [number]);
    let outStr = "";
    if (arr.length === 1) {
        outStr = arr.number;
    } else if (arr.length === 2) {
        //joins all with "and" but no commas
        //example: "bob and sam"
        outStr = arr.join(' dhe ');
    } else if (arr.length > 2) {
        //joins all with commas, but last one gets ", and" (oxford comma!)
        //example: "bob, joe, and sam"
        outStr = arr.slice(0, -1).join(', ') + ', dhe ' + arr.slice(-1);
    }

    return outStr;
}

const Card = ({ }) => {
    const [isOpen, setIsOpen] = useState(false);
    const classes = useStyles({ isOpen });
    const { bedroomNr } = useBuildingDetailsContext();
    const filteredBedroomNumbers = bedroomNr?.length > 1
       ? formatArray(bedroomNr)
        : bedroomNr[0]?.number;

    useEffect(() => {
        if (bedroomNr.length) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [bedroomNr]);

    return (
        <div className={classes.cardOuter}>
            Banesat e hijezuara janë me nga {filteredBedroomNumbers ? filteredBedroomNumbers : 0}
            {filteredBedroomNumbers === 1 ? ' dhomë' : ' dhoma'}.
            <br/>Kliko për katin.
        </div>
    )
};

export default Card;